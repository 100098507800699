import React, { FunctionComponent, useState, useMemo, useCallback } from 'react';
import { useAppContext, Stage } from '../../AppContext';
import { MDBCol, MDBRow, MDBCard, MDBBtn, MDBCardHeader, MDBCollapse, MDBIcon, MDBCardBody, MDBInput, MDBModal, MDBModalBody } from 'mdbreact';
import { values } from '../../content/values';
import prev_active from "../../assets/img/prev_active.png";
import ValuesCard from '../ValuesCard';
import Step3 from './Step3';
import { Link } from 'react-router-dom';
import DropdownCard from '../DropdownCard';

interface Props {
  collapsed?: boolean;
  reset: () => void;
}

const Step4: FunctionComponent<Props> = ({ reset, collapsed = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!collapsed);
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const toggleModal = useCallback(() => setModalOpen(prev => !prev), []);

  const { appContext, dispatch } = useAppContext();
  const {
    finalSelection,
    option1,
    option2,
    option3,
    option1PositiveStatement,
    option1NegativeStatement,
    option2PositiveStatement,
    option2NegativeStatement,
    option3PositiveStatement,
    option3NegativeStatement,
    decision,
    peopleInvolved2,
    moreInformation,
    whats,
    whens,
  } = appContext;

  const setOption1 = useCallback((arg: string) => {
    dispatch({
      type: "option1",
      payload: arg
    });
  }, [dispatch]);
  const setOption2 = useCallback((arg: string) => {
    dispatch({
      type: "option2",
      payload: arg
    });
  }, [dispatch]);
  const setOption3 = useCallback((arg: string) => {
    dispatch({
      type: "option3",
      payload: arg
    });
  }, [dispatch]);
  const setOption1PositiveStatement = useCallback((arg: string) => {
    dispatch({
      type: "option1PositiveStatement",
      payload: arg
    });
  }, [dispatch]);
  const setOption2PositiveStatement = useCallback((arg: string) => {
    dispatch({
      type: "option2PositiveStatement",
      payload: arg
    });
  }, [dispatch]);
  const setOption3PositiveStatement = useCallback((arg: string) => {
    dispatch({
      type: "option3PositiveStatement",
      payload: arg
    });
  }, [dispatch]);
  const setOption1NegativeStatement = useCallback((arg: string) => {
    dispatch({
      type: "option1NegativeStatement",
      payload: arg
    });
  }, [dispatch]);
  const setOption2NegativeStatement = useCallback((arg: string) => {
    dispatch({
      type: "option2NegativeStatement",
      payload: arg
    });
  }, [dispatch]);
  const setOption3NegativeStatement = useCallback((arg: string) => {
    dispatch({
      type: "option3NegativeStatement",
      payload: arg
    });
  }, [dispatch]);
  const setDecision = useCallback((arg: string) => {
    dispatch({
      type: "decision",
      payload: arg
    });
  }, [dispatch]);
  const setPeopleInvolved2 = useCallback((arg: string) => {
    dispatch({
      type: "peopleInvolved2",
      payload: arg
    });
  }, [dispatch]);
  const setMoreInformation = useCallback((arg: string) => {
    dispatch({
      type: "moreInformation",
      payload: arg
    });
  }, [dispatch]);
  const setWhats = useCallback((arg: string, index: 0 | 1 | 2 | 3 | 4) => {
    dispatch({
      type: "whats",
      index: index,
      payload: arg
    });
  }, [dispatch]);
  const setWhens = useCallback((arg: string, index: 0 | 1 | 2 | 3 | 4) => {
    dispatch({
      type: "whens",
      index: index,
      payload: arg
    });
  }, [dispatch]);
  const setStage = useCallback((arg: Stage) => {
    dispatch({
      type: "stage",
      payload: arg
    });
  }, [dispatch]);

  const toggleBtn = useMemo(() => {
    return (
      <MDBBtn
        onClick={() => setIsOpen(prev => !prev)}
        color="info"
        className="round-btn-icon p-0 m-0"
        size="sm"
      >
        {isOpen ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />}
      </MDBBtn>
    );
  }, [isOpen]);

  const prevBtn = useMemo(() => {
    return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step3)}>
      <img alt="next" height="25em" className="float-left" src={prev_active} />
    </div>
  }, [setStage]);

  const startOverBtn = useMemo(() => {
    return <MDBBtn className="m-5 rounded-pill border-secondary border float-right" color="default" onClick={reset}>
      <span className="title mr-2">Start Over</span><MDBIcon icon="sync" />
    </MDBBtn>
  }, [reset]);

  const downloadSample = useCallback(() => {
    window.open(require('../../assets/samples/Demo_printout.pdf'), "_blank")
  }, []);

  return (
    <div className="mt-5">
      <Step3 done collapsed />
      <div className="mt-5">
        {toggleBtn} <span className="title align-middle">Step 4. </span> {isOpen && <span className="title align-middle">Plan Next Steps</span>}
        <MDBCollapse isOpen={isOpen}>
          <MDBCard className="rounded exercise-card-bg border border-secondary mt-3">
            <MDBCardBody>
              <p className="text-secondary">Click on the values you have chosen and see how people act who have those values.</p>
              <MDBRow>
                {
                  finalSelection.map((value, index) => {
                    const fullValue = values.find((item) => value === item.name);
                    if (fullValue) {
                      return (
                        <MDBCol key={value + index} sm="6" size="12" className="mt-2">
                          <ValuesCard value={fullValue} />
                        </MDBCol>
                      );
                    } else {
                      return (
                        <MDBCol key={value + index} sm="6" size="12" className="mt-2">
                          <DropdownCard title={value}>
                            <p>This is a custom value that you have selected.</p>
                          </DropdownCard>
                        </MDBCol>
                      );
                    }
                  })
                }
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <p className="m-4 text-secondary">Write a statement about each positive and negative outcome. What value is most important in each outcome?</p>
          <MDBRow>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="light-grey-bg border border-secondary">
                <MDBCardHeader className="p-2 px-4 green-bg">
                  <h5 className="title">Option One</h5>
                  <MDBInput
                    type='text'
                    containerClass="m-0"
                    hint="Type text here"
                    value={option1}
                    getValue={value => setOption1(value.toString())}
                  />
                </MDBCardHeader>
                <MDBCardBody className="p-2 px-4">
                  <h5 className="options-header text-info">Positive Statement</h5>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={option1PositiveStatement}
                    getValue={(value) => setOption1PositiveStatement(value.toString())}
                  />
                  <h5 className="options-header grey-text mt-2">Negative Statement</h5>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={option1NegativeStatement}
                    getValue={(value) => setOption1NegativeStatement(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="light-grey-bg border border-secondary">
                <MDBCardHeader className="p-2 px-4 green-bg">
                  <h5 className="title">Option Two</h5>
                  <MDBInput
                    type='text'
                    containerClass="m-0"
                    hint="Type text here"
                    value={option2}
                    getValue={value => setOption2(value.toString())}
                  />
                </MDBCardHeader>
                <MDBCardBody className="p-2 px-4">
                  <h5 className="options-header text-info">Positive Statement</h5>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={option2PositiveStatement}
                    getValue={(value) => setOption2PositiveStatement(value.toString())}
                  />
                  <h5 className="options-header grey-text mt-2">Negative Statement</h5>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={option2NegativeStatement}
                    getValue={(value) => setOption2NegativeStatement(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="light-grey-bg border border-secondary">
                <MDBCardHeader className="p-2 px-4 green-bg">
                  <h5 className="title">Option Three</h5>
                  <MDBInput
                    type='text'
                    containerClass="m-0"
                    hint="Type text here"
                    value={option3}
                    getValue={value => setOption3(value.toString())}
                  />
                </MDBCardHeader>
                <MDBCardBody className="p-2 px-4">
                  <h5 className="options-header text-info">Positive Statement</h5>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={option3PositiveStatement}
                    getValue={(value) => setOption3PositiveStatement(value.toString())}
                  />
                  <h5 className="options-header grey-text mt-2">Negative Statement</h5>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={option3NegativeStatement}
                    getValue={(value) => setOption3NegativeStatement(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <h3 className="title text-center m-4">Now it is time to make a plan</h3>
          <MDBRow>
            <MDBCol className="text-center">
              <span className="text-secondary">What decision have you made?</span>
              <MDBInput
                containerClass="my-0 py-0"
                outline background
                type="text"
                value={decision}
                getValue={(value) => setDecision(value.toString())}
              />
            </MDBCol>
            <MDBCol>
            </MDBCol>
            <MDBCol className="text-center">
              <span className="text-secondary">Who is involved in this decision?</span>
              <MDBInput
                containerClass="my-0 py-0"
                outline background
                type="text"
                value={peopleInvolved2}
                getValue={(value) => setPeopleInvolved2(value.toString())}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="text-center">
              <span className="text-secondary">Do you need more information?</span>
              <MDBInput
                containerClass="my-0 py-0"
                outline background
                type="text"
                value={moreInformation}
                getValue={(value) => setMoreInformation(value.toString())}
              />
            </MDBCol>
          </MDBRow>
          <MDBCard className="rounded exercise-card-bg border border-secondary mt-3">
            <MDBCardBody>
              <p className="text-secondary">
                Make a list of action steps for the next week, month and so on.
              </p>
              <p className="text-secondary">
                If you are still conflicted, review this exercise with a trusted friend, advisor, relative or co-worker to get their input. You can also <Link to="/contact">contact us</Link> for an online consultation.
              </p>
              <MDBRow>
                <MDBCol className="title" size="9">
                  What
                </MDBCol>
                <MDBCol className="title" size="3">
                  When
                </MDBCol>
              </MDBRow>
              {
                whats.map((what, index) => {
                  return (
                    <MDBRow>
                      <MDBCol size="9">
                        {`${index + 1}. `} <MDBInput
                          type="text"
                          style={{ width: "90%" }}
                          containerClass="m-0 p-0 d-inline"
                          className="m-0 p-0 d-inline"
                          value={what}
                          //@ts-ignore
                          getValue={value => setWhats(value.toString(), index)}
                        />
                      </MDBCol>
                      <MDBCol size="3">
                        <MDBInput
                          type="text"
                          containerClass="m-0 p-0 d-inline"
                          className="m-0 p-0 d-inline w-100"
                          value={whens[index]}
                          //@ts-ignore
                          getValue={value => setWhens(value.toString(), index)}
                        />
                      </MDBCol>
                    </MDBRow>
                  );
                })
              }
            </MDBCardBody>
          </MDBCard>
          <MDBModal
            isOpen={modalOpen}
            toggle={toggleModal}
            frame
            position="bottom"
            inline={false}
            backdrop={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={false}
          >
            <MDBModalBody className="text-center">
              <span>Download an example exercise printout?</span>
              <MDBBtn
                size="sm"
                color="default"
                className="border border-secondary"
                onClick={downloadSample}
              ><span className="title">Download</span></MDBBtn>
              <MDBBtn
                size="sm"
                color="grey"
                onClick={toggleModal}
              >
                Close
              </MDBBtn>
            </MDBModalBody>
          </MDBModal>
          <MDBRow>
            <MDBCol>
              {prevBtn}
            </MDBCol>
            <MDBCol>
              {startOverBtn}
            </MDBCol>
          </MDBRow>
          <MDBCard>
            <MDBCardBody>
              <span>Download an example exercise printout?</span>
              <MDBBtn
                size="sm"
                color="default"
                className="border border-secondary"
                onClick={downloadSample}
              ><span className="title">Download</span></MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCollapse>
      </div>
    </div>
  );
}

export default Step4;