import React, { FunctionComponent } from "react";
import { MDBCard, MDBCardBody, MDBIcon, MDBTooltip } from "mdbreact";

interface Props {
  title: string;
  description?: string;
  onToggle?: (toggled: boolean) => void;
  toggled?: boolean;
}

const ToggleCard: FunctionComponent<Props> = ({ title, description, onToggle, toggled = false }) => {
  const toggle = () => {
    if (onToggle) onToggle(!toggled);
  };

  return (
    <MDBCard
      className={toggled ? "blue-bg rounded" : "green-bg"}
      onClick={toggle}
    >
      <MDBCardBody className="py-2">
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" disabled checked={toggled} />
          <label className="custom-control-label"><span className={toggled ? "white-text" : "text-secondary"}>{title}</span></label>
          {
            description &&
            <div className="align-middle float-right">
              <MDBTooltip placement="bottom" domElement>
                <span>
                  <MDBIcon inverse={toggled} icon="question-circle" />
                </span>
                <span>
                  {description}
                </span>
              </MDBTooltip>
            </div>
          }
        </div>
      </MDBCardBody>
    </MDBCard>
  );
}

export default ToggleCard;