import React, {
  createContext,
  useContext,
  FunctionComponent,
  Dispatch,
} from 'react';
import { values } from './content/values';

export enum Stage {
  Step1A,
  Step1B,
  Step2A,
  Step2B,
  Step3,
  Step4,
}

export type DispatchAction  ={
  type: 'email',
  payload: string | null,
} | {
  type: 'activeTab',
  payload: string,
} | {
  type: "allValues",
  payload: Array<string> | ((arg: string[]) => string[]),
} | {
  type: "stage",
  payload: Stage,
} | {
  type: "initialSelection",
  payload: Array<string> | ((arg: string[]) => string[]),
} | {
  type: "finalSelection",
  payload: Array<string> | ((arg: string[]) => string[]),
} | {
  type: "positiveResult1You",
  payload: string,
} | {
  type: "positiveResult1Others",
  payload: string,
} | {
  type: "negativeResult1You",
  payload: string,
} | {
  type: "negativeResult1Others",
  payload: string,
} | {
  type: "positiveResult2You",
  payload: string,
} | {
  type: "positiveResult2Others",
  payload: string,
} | {
  type: "negativeResult2You",
  payload: string,
} | {
  type: "negativeResult2Others",
  payload: string,
} | {
  type: "positiveResult3You",
  payload: string,
} | {
  type: "positiveResult3Others",
  payload: string,
} | {
  type: "negativeResult3You",
  payload: string,
} | {
  type: "negativeResult3Others",
  payload: string,
} | {
  type: "option1",
  payload: string,
} | {
  type: "option2",
  payload: string,
} | {
  type: "option3",
  payload: string,
} | {
  type: "bigDecision",
  payload: string,
} | {
  type: "peopleInvolved",
  payload: string,
} | {
  type: "option1PositiveStatement",
  payload: string,
} | {
  type: "option1NegativeStatement",
  payload: string,
} | {
  type: "option2PositiveStatement",
  payload: string,
} | {
  type: "option2NegativeStatement",
  payload: string,
} | {
  type: "option3PositiveStatement",
  payload: string,
} | {
  type: "option3NegativeStatement",
  payload: string,
} | {
  type: "decision",
  payload: string,
} | {
  type: "peopleInvolved2",
  payload: string,
} | {
  type: "moreInformation",
  payload: string,
} | {
  type: "whats",
  index: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
  payload: string,
} | {
  type: "whens",
  index: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
  payload: string,
} | {
  type: "reset",
};

export const gsReducer: React.Reducer<AppContext, DispatchAction> = (state, action) => {
  let store: AppContext;
  switch (action.type) {
    case 'email':
      store = {
        ...state,
        email: action.payload,
      };
      return store;
    case 'reset':
      store = {
        ...defaultAppContext,
        activeTab: state.activeTab,
      };
      return store;
    case 'activeTab':
      store = {
        ...state,
        activeTab: action.payload,
      };
      return store;
    case 'allValues':
      if (action.payload instanceof Function) {
        store = {
          ...state,
          allValues: action.payload(state.allValues),
        }
      } else {
        store = {
          ...state,
          allValues: action.payload,
        }
      }
      return store;
    case 'stage':
      store = {
        ...state,
        stage: action.payload,
      }
      return store;
    case 'initialSelection':
      if (action.payload instanceof Function) {
        store = {
          ...state,
          initialSelection: action.payload(state.initialSelection),
        }
      } else {
        store = {
          ...state,
          initialSelection: action.payload,
        }
      }
      return store;
    case 'finalSelection':
      if (action.payload instanceof Function) {
        store = {
          ...state,
          finalSelection: action.payload(state.finalSelection),
        }
      } else {
        store = {
          ...state,
          finalSelection: action.payload,
        }
      }
      return store;
    case 'positiveResult1You':
      store = {
        ...state,
        positiveResult1You: action.payload,
      }
      return store;
    case 'positiveResult1Others':
      store = {
        ...state,
        positiveResult1Others: action.payload,
      }
      return store;
    case 'negativeResult1You':
      store = {
        ...state,
        negativeResult1You: action.payload,
      }
      return store;
    case 'negativeResult1Others':
      store = {
        ...state,
        negativeResult1Others: action.payload,
      }
      return store;
    case 'positiveResult2You':
      store = {
        ...state,
        positiveResult2You: action.payload,
      }
      return store;
    case 'positiveResult2Others':
      store = {
        ...state,
        positiveResult2Others: action.payload,
      }
      return store;
    case 'negativeResult2You':
      store = {
        ...state,
        negativeResult2You: action.payload,
      }
      return store;
    case 'negativeResult2Others':
      store = {
        ...state,
        negativeResult2Others: action.payload,
      }
      return store;
    case 'positiveResult3You':
      store = {
        ...state,
        positiveResult3You: action.payload,
      }
      return store;
    case 'positiveResult3Others':
      store = {
        ...state,
        positiveResult3Others: action.payload,
      }
      return store;
    case 'negativeResult3You':
      store = {
        ...state,
        negativeResult3You: action.payload,
      }
      return store;
    case 'negativeResult3Others':
      store = {
        ...state,
        negativeResult3Others: action.payload,
      }
      return store;
    case 'option1':
      store = {
        ...state,
        option1: action.payload,
      }
      return store;
    case 'option2':
      store = {
        ...state,
        option2: action.payload,
      }
      return store;
    case 'option3':
      store = {
        ...state,
        option3: action.payload,
      }
      return store;
    case 'bigDecision':
      store = {
        ...state,
        bigDecision: action.payload,
      }
      return store;
    case 'peopleInvolved':
      store = {
        ...state,
        peopleInvolved: action.payload,
      }
      return store;
    case 'option1PositiveStatement':
      store = {
        ...state,
        option1PositiveStatement: action.payload,
      }
      return store;
    case 'option1NegativeStatement':
      store = {
        ...state,
        option1NegativeStatement: action.payload,
      }
      return store;
    case 'option2PositiveStatement':
      store = {
        ...state,
        option2PositiveStatement: action.payload,
      }
      return store;
    case 'option2NegativeStatement':
      store = {
        ...state,
        option2NegativeStatement: action.payload,
      }
      return store;
    case 'option3PositiveStatement':
      store = {
        ...state,
        option3PositiveStatement: action.payload,
      }
      return store;
    case 'option3NegativeStatement':
      store = {
        ...state,
        option3NegativeStatement: action.payload,
      }
      return store;
    case 'decision':
      store = {
        ...state,
        decision: action.payload,
      }
      return store;
    case 'peopleInvolved2':
      store = {
        ...state,
        peopleInvolved2: action.payload,
      }
      return store;
    case 'moreInformation':
      store = {
        ...state,
        moreInformation: action.payload,
      }
      return store;
    case 'whats':
      const _whats = [...state.whats];
      _whats[action.index] = action.payload;
      store = {
        ...state,
        //@ts-ignore
        whats: _whats,
      }
      return store;
    case 'whens':
      const _whens = [...state.whens];
      _whens[action.index] = action.payload;
      store = {
        ...state,
        //@ts-ignore
        whens: _whens,
      }
      return store;
  }
};

type AppContext = {
  email: string | null;
  activeTab: string;
  allValues: Array<string>;
  stage: Stage;
  initialSelection: Array<string>;
  finalSelection: Array<string>;
  positiveResult1You: string;
  positiveResult1Others: string;
  negativeResult1You: string;
  negativeResult1Others: string;
  positiveResult2You: string;
  positiveResult2Others: string;
  negativeResult2You: string;
  negativeResult2Others: string;
  positiveResult3You: string;
  positiveResult3Others: string;
  negativeResult3You: string;
  negativeResult3Others: string;
  option1: string;
  option2: string;
  option3: string;
  bigDecision: string;
  peopleInvolved: string;
  peopleInvolved2: string;
  option1PositiveStatement: string;
  option1NegativeStatement: string;
  option2PositiveStatement: string;
  option2NegativeStatement: string;
  option3PositiveStatement: string;
  option3NegativeStatement: string;
  decision: string;
  moreInformation: string;
  whats: [string, string, string, string, string, string, string, string];
  whens: [string, string, string, string, string, string, string, string];
};

type AppContextType = {
  appContext: AppContext,
  dispatch: Dispatch<DispatchAction>,
}

export const defaultAppContext: AppContext = {
  email: null,
  activeTab: '/',
  allValues: values.map(a => a.name),
  stage: Stage.Step1A,
  initialSelection: [],
  finalSelection: [],
  positiveResult1You: "",
  positiveResult1Others: "",
  negativeResult1You: "",
  negativeResult1Others: "",
  positiveResult2You: "",
  positiveResult2Others: "",
  negativeResult2You: "",
  negativeResult2Others: "",
  positiveResult3You: "",
  positiveResult3Others: "",
  negativeResult3You: "",
  negativeResult3Others: "",
  option1: "",
  option2: "",
  option3: "",
  bigDecision: "",
  peopleInvolved: "",
  peopleInvolved2: "",
  option1PositiveStatement: "",
  option1NegativeStatement: "",
  option2PositiveStatement: "",
  option2NegativeStatement: "",
  option3PositiveStatement: "",
  option3NegativeStatement: "",
  decision: "",
  moreInformation: "",
  whats: ["", "", "", "", "", "", "", ""],
  whens: ["", "", "", "", "", "", "", ""],
}

const blankAppContext: AppContextType = {
  appContext: { ...defaultAppContext },
  dispatch: (action) => { console.error("No global store provider") }
}

export const AppContext = createContext<AppContextType>(blankAppContext);

interface Props {
  providerValue: AppContextType;
}

export const AppContextProvider: FunctionComponent<Props> = ({ providerValue, children }) => {
  return (
    <AppContext.Provider value={providerValue}>
      {children}
    </AppContext.Provider>
  )
};

export const useAppContext = () => useContext<AppContextType>(AppContext);
