import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useAppContext, Stage } from '../AppContext';
import Step1A from '../components/exercise/Step1A';
import Step1B from '../components/exercise/Step1B';
import Step2B from '../components/exercise/Step2B';
import Step3 from '../components/exercise/Step3';
import Step4 from '../components/exercise/Step4';
import { MDBContainer } from 'mdbreact';
import Step2A from '../components/exercise/Step2A';

interface Props { }

const Exercise: FunctionComponent<Props> = (props) => {
  const { appContext, dispatch } = useAppContext();
  const { stage } = appContext;

  const reset = useCallback(() => {
    dispatch({ type: "reset" });
  }, [dispatch]);

  const body = useMemo(() => {
    switch (stage) {
      case Stage.Step1A:
        return <Step1A />;
      case Stage.Step1B:
        return <Step1B />;
      case Stage.Step2A:
        return <Step2A />;
      case Stage.Step2B:
        return <Step2B />
      case Stage.Step3:
        return <Step3 />
      case Stage.Step4:
        return <Step4 reset={reset} />
    }
  }, [stage, reset]);

  return (
    <MDBContainer className="mt-5 pt-5">
      {body}
    </MDBContainer>
  )
}

export default Exercise;