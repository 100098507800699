/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVcCardPrintout = /* GraphQL */ `
  mutation CreateVcCardPrintout($values: [String]) {
    createVCCardPrintout(values: $values) {
      key
      errors
    }
  }
`;
export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail(
    $input: UpdateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    updateEmail(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmail = /* GraphQL */ `
  mutation DeleteEmail(
    $input: DeleteEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    deleteEmail(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail(
    $name: String!
    $email: String!
    $subject: String!
    $message: String!
  ) {
    sendEmail(
      name: $name
      email: $email
      subject: $subject
      message: $message
    ) {
      value
      errors
    }
  }
`;
