import React, { useMemo, useReducer } from 'react';
import './App.css';
import {
  MDBContainer,
  MDBFooter,
  MDBRow,
  MDBCol
} from 'mdbreact';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import HomeView from "./views/Home";
import ExerciseView from "./views/Exercise";
import FAQView from "./views/FAQs";
import ValuesView from "./views/Values";
import ContactView from "./views/Contact";
import AboutView from "./views/About";
import CategoriesView from "./views/Categories";
import PrintoutsView from "./views/Printouts";
import VideosView from "./views/Videos";
import { gsReducer, AppContextProvider, defaultAppContext } from './AppContext';
import Navbar from './Navbar';

const App = () => {
  const [appContext, dispatch] = useReducer(gsReducer, {...defaultAppContext});
  const providerValue = useMemo(() => ({ appContext, dispatch }), [appContext, dispatch]);

  return (
    <AppContextProvider providerValue={providerValue}>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={HomeView} />
          <Route path="/about" exact component={AboutView} />
          <Route path="/faq" exact component={FAQView} />
          <Route path="/cards" exact component={ValuesView} />
          <Route path="/categories" exact component={CategoriesView} />
          <Route path="/exercise" exact component={ExerciseView} />
          <Route path="/contact" exact component={ContactView} />
          <Route path="/printouts" exact component={PrintoutsView} />
          <Route path="/videos" exact component={VideosView} />
        </Switch>
        <MDBFooter className="mt-5 py-2 footer">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12" md="3" className="p-3">
                <p>
                  <Link to="/about"><span className="text-secondary">About</span></Link><br />
                  <Link to="/contact"><span className="text-secondary">Contact Us</span></Link>
                </p>
              </MDBCol>
              <MDBCol size="12" md="9" className="p-3">
                <p style={{ fontSize: "0.75em" }} className="text-secondary">
                  ©2023 Valucision (Jackson, Kelly). All rights reserved. No part of this publication may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of the publisher. No changes or alterations without written permission.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBFooter>
      </Router>
    </AppContextProvider>
  );
}

export default App;
