import React, { useState, FunctionComponent, useCallback, useMemo } from 'react';
import { useAppContext, Stage } from '../../AppContext';
import { MDBContainer, MDBRow, MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCollapse, MDBCol } from 'mdbreact';
import { values } from '../../content/values';
import prev_active from "../../assets/img/prev_active.png";
import next_active from "../../assets/img/next_active.png";
import Step2B from './Step2B';

interface Props {
  collapsed?: boolean;
  done?: boolean;
}

const Step3: FunctionComponent<Props> = ({ collapsed = false, done = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!collapsed);

  const { appContext, dispatch } = useAppContext();
  const { finalSelection } = appContext;

  const setStage = useCallback((arg: Stage) => {
    dispatch({
      type: "stage",
      payload: arg
    });
  }, [dispatch]);

  const [tab, setTab] = useState<string | undefined>(finalSelection[0]);

  const toggleBtn = useMemo(() => {
    return (
      <MDBBtn
        onClick={() => setIsOpen(prev => !prev)}
        color="info"
        className="round-btn-icon p-0 m-0"
        size="sm"
      >
        {isOpen ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />}
      </MDBBtn>
    );
  }, [isOpen]);

  const nextBtn = useMemo(() => {
    return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step4)}>
      <img alt="next" height="25em" className="float-right" src={next_active} />
    </div>;
  }, [setStage]);

  const prevBtn = useMemo(() => {
    return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step2B)}>
      <img alt="next" height="25em" className="float-left" src={prev_active} />
    </div>
  }, [setStage]);

  return (
    <div className="mt-5">
      <Step2B done collapsed />
      <div className="mt-5">
        {toggleBtn} <span className="title align-middle">Step 3. </span> {isOpen && <span className="title align-middle">Assess Options Using Your Values</span>}
        <MDBCollapse isOpen={isOpen}>
          <p className="text-secondary">{!done && <ul>
            <li>Look at your values on the tabs at the bottom of the page.</li>
            <li>Read and answer the questions about your value for each option.</li>
            <li>Assess where you are acting like a person who has that value in each option.</li>
          </ul>}</p>
          <MDBRow>
            <MDBContainer>
              <MDBNav className="nav-tabs">
                {
                  finalSelection.map((value, index) => {
                    return (
                      <MDBNavItem key={value + index}>
                        <MDBNavLink link to="#"
                          active={tab === value}
                          onClick={() => setTab(value)}
                          role="tab"
                        >
                          <span className="title">{value}</span>
                        </MDBNavLink>
                      </MDBNavItem>
                    );
                  })
                }
              </MDBNav>
              <MDBTabContent activeItem={tab}>
                {
                  finalSelection.map((value, index) => {
                    const fullValue = values.find((item) => value === item.name);
                    const questions = fullValue?.questions;
                    return <MDBTabPane key={value + index} tabId={value} role="tabpanel">
                      <ul className="text-secondary">
                        {
                          questions
                            ? questions.map(q => <li key={q}>{q}</li>)
                            : <li>This is a custom value that you have selected.</li>
                        }
                      </ul>
                    </MDBTabPane>;
                  })
                }
              </MDBTabContent>
            </MDBContainer>
          </MDBRow>
          {!done &&
            <MDBRow end>
              <MDBCol>
                {prevBtn}
              </MDBCol>
              <MDBCol>
                {nextBtn}
              </MDBCol>
            </MDBRow>}
        </MDBCollapse>
      </div>
    </div>
  );
}

export default Step3;




