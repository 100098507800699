import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useAppContext, Stage } from '../../AppContext';
import { MDBCol, MDBRow, MDBBtn, MDBTooltip, MDBIcon, MDBCollapse, MDBCard, MDBCardBody } from 'mdbreact';
import { values } from '../../content/values';
import ToggleCard from '../ToggleCard';
import next_active from "../../assets/img/next_active.png";
import prev_active from "../../assets/img/prev_active.png";
import next_inactive from "../../assets/img/next_inactive.png";
import ValuesCard from '../ValuesCard';
import DropdownCard from '../DropdownCard';
import { Link } from 'react-router-dom';

interface Props {
  collapsed?: boolean;
  done?: boolean;
}

const Step1B: FunctionComponent<Props> = ({ collapsed = false, done = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!collapsed);
  const { appContext, dispatch } = useAppContext();
  const { finalSelection, initialSelection } = appContext;

  const setFinalSelection = useCallback((arg: Array<string> | ((arg0: string[]) => string[])) => {
    dispatch({
      type: "finalSelection",
      payload: arg
    });
  }, [dispatch]);
  const setStage = useCallback((arg: Stage) => {
    dispatch({
      type: "stage",
      payload: arg
    });
  }, [dispatch]);

  const cards = initialSelection.map((value, index) => {
    const description = values.find((fullValue) => value === fullValue.name)?.description;
    return (
      <MDBCol key={value + index} md="6" lg="4" xl="3" size="12" className="mt-2">
        {finalSelection.length >= 4 && !finalSelection.includes(value) ?
          (
            <MDBTooltip placement="bottom" domElement>
              <div>
                <ToggleCard
                  description={description}
                  toggled={finalSelection.find((item) => item === value) !== undefined}
                  title={value + (finalSelection.findIndex((item) => item === value) >= 0 ? ` (${ finalSelection.findIndex((item) => item === value) + 1})` : "")}
                  onToggle={(toggle: boolean) => {
                    if (!toggle) setFinalSelection(prev => prev.filter((item) => item !== value));
                  }}
                />
              </div>
              <div>Select at Most 4 Values</div>
            </MDBTooltip>
          ) : (
            <ToggleCard
              description={description}
              toggled={finalSelection.find((item) => item === value) !== undefined}
              title={value + (finalSelection.findIndex((item) => item === value) >= 0 ? ` (${ finalSelection.findIndex((item) => item === value) + 1})` : "")}
              onToggle={(toggle: boolean) => {
                if (toggle) setFinalSelection(prev => [...prev, value]);
                else setFinalSelection(prev => prev.filter((item) => item !== value));
              }}
            />
          )
        }
      </MDBCol>
    );
  });

  const done_cards = finalSelection.map((value, index) => {
    const fullValue = values.find((_fullValue) => value === _fullValue.name);
    if (fullValue) {
      return (
        <MDBCol key={value + index} md="6" size="12" className="mt-2">
          <ValuesCard value={fullValue} order={index + 1} />
        </MDBCol>
      );
    } else {
      return (
        <MDBCol key={value + index} md="6" size="12" className="mt-2">
          <DropdownCard title={`${index + 1}. ${value}`}>
            <p>This is a custom value that you have selected.</p>
          </DropdownCard>
        </MDBCol>
      );
    }
  });

  const toggleBtn = useMemo(() => {
    return (
      <MDBBtn
        onClick={() => setIsOpen(prev => !prev)}
        color="info"
        className="round-btn-icon p-0 m-0"
        size="sm"
      >
        {isOpen ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />}
      </MDBBtn>
    );
  }, [isOpen]);

  const nextBtn = useMemo(() => {
    if (finalSelection.length) {
      return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step2A)}>
        <img alt="next" height="25em" className="float-right" src={next_active} />
      </div>
    } else {
      return <div className="m-5">
        <img alt="next" height="25em" className="float-right" src={next_inactive} />
      </div>
    }
  }, [finalSelection, setStage]);

  const prevBtn = useMemo(() => {
    return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step1A)}>
      <img alt="next" height="25em" className="float-left" src={prev_active} />
    </div>
  }, [setStage]);

  return (
    <div className="mt-5">
      {toggleBtn} <span className="title align-middle">{!done ? `Step 1B.` : `Step 1.`}</span> {isOpen && <span className="title align-middle">{done ? `Values` : `Narrow Your List`}</span>}
      <MDBCollapse isOpen={isOpen}>
        <p style={{ paddingLeft: "82px" }}>{!done && <span>Next, shorten your list. Choose at most four values. Put them in order of preference. <br />See <Link to="/categories">Value Categories</Link> for help in prioritizing.</span>}</p>
        <MDBCard className="rounded exercise-card-bg border border-secondary">
          <MDBCardBody>
            <MDBRow>
              {done ? done_cards : cards}
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        {!done && <MDBRow end>
          <MDBCol>
            {prevBtn}
          </MDBCol>
          <MDBCol>
            {nextBtn}
          </MDBCol>
        </MDBRow>}
      </MDBCollapse>
    </div>
  );
}

export default Step1B;