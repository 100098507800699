import React, { FunctionComponent, useState } from "react";
import { MDBCard, MDBCardBody, MDBIcon, MDBCardHeader, MDBCollapse, MDBBtn } from "mdbreact";

interface Props {
  title: string;
}

const DropdownCard: FunctionComponent<Props> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleOpen = () => setIsOpen(prev => !prev);

  return (
    <>
      <MDBCard style={{ borderRadius: "8px" }}>
        <MDBCardHeader onClick={toggleOpen} style={{ borderRadius: "8px" }} className={isOpen ? "cardHeaderGreen px-4" : "cardHeaderBlue px-4"}>
          <MDBBtn
            color={isOpen ? "secondary" : "default"}
            className="round-btn-icon p-0 m-0"
            size="sm"
            style={{ position: "relative", right: "39px" }}
          >
            {isOpen ? <MDBIcon icon="angle-up" /> : <MDBIcon icon="angle-down" />}
          </MDBBtn>
          <div className="pl-3 d-inline-block align-middle" style={{ position: "relative", width: "80%", right: "39px" }}>{title}</div>
        </MDBCardHeader>
        <MDBCollapse isOpen={isOpen}>
          <MDBCardBody className="light-grey-bg">
            {children}
          </MDBCardBody>
        </MDBCollapse>
      </MDBCard>
    </>
  );
}

export default DropdownCard;