import React, { FunctionComponent, useMemo } from "react";
import { MDBTooltip, MDBIcon } from "mdbreact";
import { values } from "../content/values";

interface Props {
  value: string;
}

const ValueTooltip: FunctionComponent<Props> = ({ value }) => {
  const description = useMemo(() => {
    const info = values.find((_value) => _value.name === value);
    return info?.description || "";
  }, [value]);

  return (
    <MDBTooltip placement="top" tag="span" domElement>
      <span><strong>{value} <MDBIcon icon="question-circle"/></strong></span>
      <div>
        {description}
      </div>
    </MDBTooltip>
  );
}

export default ValueTooltip;