import React, { FunctionComponent, useState } from 'react';
import { MDBBtn, MDBContainer } from 'mdbreact';
import { useAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import ValuesListPicker from '../components/ValuesListPicker';
import { createVcCardPrintout } from '../graphql/mutations';
import { CreateVcCardPrintoutMutation, CreateVcCardPrintoutMutationVariables } from '../API';

interface Props { }

const PRINTOUT_KEY = "valucision_printout.pdf";

const Printouts: FunctionComponent<Props> = (props) => {
  const { appContext } = useAppContext();
  const { email } = appContext;

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const downloadS3File = (key: string) => {
    Storage.get(key, { level: 'public', expires: 60 })
      .then(endpoint => {
        window.open(endpoint.toString(), "_blank");
      })
      .catch(err => console.error(err));
  }

  const createPrintout = async (values: string[]) => {
    const input: CreateVcCardPrintoutMutationVariables = {
      values: values,
    };
    const gqlInput = graphqlOperation(createVcCardPrintout, input);
    return (API.graphql(gqlInput) as Promise<GraphQLResult<CreateVcCardPrintoutMutation>>)
      .then((res: GraphQLResult<CreateVcCardPrintoutMutation>) => {
        if (res.errors) {
          console.error(res.errors);
        }
        if (res.data?.createVCCardPrintout?.errors) {
          console.error(res.data.createVCCardPrintout.errors);
        }
        if (res.data?.createVCCardPrintout?.key) {
          return res.data.createVCCardPrintout.key;
        }
        return null;
      })
      .catch((error: any) => {
        console.error(error);
        return null;
      });
  }

  const downloadPrintout = async () => {
    const key: string | null = await createPrintout(selectedValues);
    if (key) {
      downloadS3File(key);
    } else {
      alert("Error downloading file");
    }
  }

  if (email) {
    return (
      <MDBContainer className="mt-5 pt-5">
        <div className="mb-5 pb-2">
          <h2>VC Exercise Printout</h2>
          <MDBBtn
            className="m-0"
            onClick={() => downloadS3File(PRINTOUT_KEY)}
            color="primary">
            Download Blank Exercise
          </MDBBtn>
        </div>
        <div>
          <h2>ValuCards Printout</h2>
          <p>Choose 1 to 4 values to create a printout.</p>
          <ValuesListPicker
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            maxSelected={4} />
          <MDBBtn
            className="mx-0 mt-2"
            onClick={downloadPrintout}
            disabled={!!!selectedValues.length}
            color="primary">
            Download ValuCards
          </MDBBtn>
        </div>
      </MDBContainer>
    );
  } else {
    return (
      <MDBContainer className="mt-5 pt-5">
        <p>You must be logged in to use this feature.</p>
        <p>If you do not have an account, you can reach out to us <Link to="/contact">here</Link> for more information.</p>
      </MDBContainer>
    );
  }
}


export default Printouts;