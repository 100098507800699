import React, { FunctionComponent } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBAnimation } from 'mdbreact';
import ValuesCard from '../components/ValuesCard';
import { values } from '../content/values';

interface Props {

}

export interface value {
  name: string;
  description: string;
  people: string[];
  questions: string[];
}

const Values: FunctionComponent<Props> = (props) => {
  const cards = values.map((value, index) => {
    return (
      <MDBCol key={value.name + index} lg="6" size="12" className="mt-2">
        <MDBAnimation type="zoomInUp" delay={`${index * 100}ms`}>
          <ValuesCard value={value} addToValuesBtn />
        </MDBAnimation>
      </MDBCol>
    );
  });

  return (
    <MDBContainer className="mt-5 pt-5">
      <div className="text-center mt-5">
        <h3 className="title">ValuCards™</h3>
        <p>
          Your values are the things that you believe are important and worthwhile in the way you live. <br />
          You are happier when you align your values with your decisions and actions.
        </p>
        <p>
          You will use these ValuCards™ in the exercise to help with decision making. <br />
          We provide the entire list here for you to explore values more deeply.
        </p>
        <p>ValuCards™ include</p>
      </div>
      <div className="d-flex justify-content-center">
        <ul>
          <li>The value definition</li>
          <li>Questions about the value for each decision option, and</li>
          <li>Behaviors of people who prioritize that value.</li>
        </ul>
      </div>
      <MDBRow className="mt-1">
        {cards}
      </MDBRow>
    </MDBContainer>
  );
}


export default Values;