import React, { FunctionComponent, useState } from 'react';
import { MDBContainer, MDBCardBody, MDBCard, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import { API } from 'aws-amplify';
import { sendEmail } from '../graphql/mutations';
import { SendEmailMutation } from '../API';
import { useAppContext } from '../AppContext';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

interface Props {}

const Contact: FunctionComponent<Props> = (props) => {
  const { appContext } = useAppContext();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>(appContext.email || "");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [sent, setSent] = useState<boolean | null>(false);

  const send = () => {
    setSent(null);
    (API.graphql({
      query: sendEmail, 
      variables: {
        name: name,
        email: email,
        subject: subject,
        message: message,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }) as Promise<{ data: SendEmailMutation }>)
      .then((res: { data: SendEmailMutation }) => {
        const body = res.data.sendEmail;
        if (body.errors !== null) {
          body.errors.forEach(error => console.error(error));
        }
        if (body.value === false) {
          alert("There was an error sending this message");
        }
        setSent(body.value);
      }).catch((error: any) => {
        alert("There was an error sending this message");
        console.error(error);
        setSent(false);
      });
  };

  const saveValidator = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    (event.target as HTMLFormElement).className += " was-validated";
    send();
  };

  return (
    <>
      <MDBContainer className="mt-5 pt-5">
        <MDBCard className="mt-5">
          <MDBCardBody className="light-grey-bg">
            <form className="needs-validation" onSubmit={saveValidator}>
              <p className="h5 text-center mb-4 title">Contact Us</p>
              <p className="text-center">We are available for personal consulting and will do workshops for groups larger than 10.</p>
              <p className="text-center">Diane Jackson<br /><a href="mailto:valucision@gmail.com">valucision@gmail.com</a></p>
              <div className="grey-text">
                <MDBInput
                  disabled={sent === null || sent}
                  background={sent === null || sent}
                  required
                  value={name}
                  getValue={(value) => setName(value.toString())}
                  label="Your name"
                  icon="user"
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right" />
                <MDBInput
                  background={sent === null || sent}
                  disabled={sent === null || sent}
                  required
                  value={email}
                  getValue={(value) => setEmail(value.toString())}
                  label="Your email"
                  icon="envelope"
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right" />
                <MDBInput
                  background={sent === null || sent}
                  disabled={sent === null || sent}
                  required
                  value={subject}
                  getValue={(value) => setSubject(value.toString())}
                  label="Subject"
                  icon="tag"
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right" />
                <MDBInput
                  background={sent === null || sent}
                  disabled={sent === null || sent}
                  required
                  value={message}
                  getValue={(value) => setMessage(value.toString())}
                  type="textarea"
                  rows="2"
                  label="Your message"
                  icon="pencil-alt" />
              </div>
              <div className="text-center">
                <MDBBtn className="border border-secondary" disabled={sent === null || sent} outline={sent === null || sent} color="default" type="submit">
                  <span className="title">{sent !== null ? (sent ? `Sent` : `Send`) : `Sending`}</span>
                  <MDBIcon far icon="paper-plane" className="ml-1" />
                </MDBBtn>
              </div>
            </form>
          </MDBCardBody>
        </MDBCard>
        <div className="mt-3">
          Disclaimer: We are not licensed counselors. All coaching services and communication, email or otherwise, delivered by the Valucision team, as well as information set forth on this website (valucision.com) are meant to help people identify their values and show them how to use them in decision making. By participating in this exercise, users clearly understand, per this disclaimer, that they have the right to determine their own personal values and are responsible for their own actions, choices, and the results of those choices.
        </div>
      </MDBContainer>

    </>
  );
}

export default Contact;