import React, { FunctionComponent } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody, MDBBtn } from 'mdbreact';
import peopleImg from '../assets/img/VC_home_1920.jpg';
import betterDecisions from '../assets/img/Better_Decisions.png';
import coaching from '../assets/img/Coaching.png';
import workbook from '../assets/img/workbook.png';
import workshop from '../assets/img/workshop.png';
import comingsoon from '../assets/img/Comingsoon.png';
import { withRouter, RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {}


const Home: FunctionComponent<Props> = ({ history }) => {

  const goToContact = () => {
    history.push('/contact');
  }

  const goToDemo = () => {
    history.push('/exercise');
  }

  return (
    <div className='mt-5'>
      <img alt="smiling people" src={peopleImg} className="img-fluid" />
      <MDBContainer fluid>
        <h3 className="text-center mt-2 title">WE HELP COUNSELORS HELP PEOPLE WITH CHANGE</h3>
        <p style={{ fontSize: "0.9em" }} className="text-center">
          Valucision is a simple counseling technique for people making life-changing decisions.<br />
          Your clients will gain confidence they are making their best choice!
        </p>
        <div className="d-flex justify-content-center">
          <img alt="Better Decisions. Better Outcomes." src={betterDecisions} height="16px" />
        </div>
        <MDBRow className="mt-5" center>
          <MDBCol size="12" md="6" lg="4" className="px-4">
            <div className="d-flex justify-content-center">
              <MDBCard className="w-100">
                <MDBCardHeader style={{ fontSize: "2em" }} className="cardHeaderBlue text-center p-0">
                  Workshop
                </MDBCardHeader>
                <MDBCardBody className="light-grey-bg homeCardBody">
                  <h5 className="text-secondary text-center">Train-the-Trainer Groups</h5>
                  <p className="text-info text-center">Our two-hour training will equip you to help your clients to</p>
                  <ul className="text-secondary">
                    <li style={{ fontSize: "0.9em" }}>Decide their core values</li>
                    <li style={{ fontSize: "0.9em" }}>Imagine their outcomes</li>
                    <li style={{ fontSize: "0.9em" }}>Link their values and outcomes</li>
                    <li style={{ fontSize: "0.9em" }}>Make a plan forward</li>
                  </ul>
                  <p className="text-info text-center"><strong>2hr/$90</strong><br/>per person</p>
                </MDBCardBody>
                <img alt="workshop" src={workshop} className="w-100" />
              </MDBCard>
            </div>
            <MDBBtn onClick={goToContact} className="px-0 py-1 mx-0 w-100 border border-secondary">
              <span className="title" style={{ fontSize: "2em" }}>BOOK NOW</span>
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12" md="6" lg="4" className="px-4">
            <div className="d-flex justify-content-center">
              <MDBCard className="w-100">
                <MDBCardHeader style={{ fontSize: "2em" }} className="cardHeaderBlue text-center p-0">
                  Online Coaching
                </MDBCardHeader>
                <MDBCardBody className="light-grey-bg homeCardBody">
                  <h5 className="text-secondary text-center">Coaching targeted for specific issues or to brush-up your technique</h5>
                  <p className="text-info text-center"><strong>1hr/$70</strong></p>
                </MDBCardBody>
                <img alt="workshop" src={coaching} className="w-100" />
              </MDBCard>
            </div>
            <MDBBtn onClick={goToContact} className="px-0 py-1 mx-0 w-100 border border-secondary">
              <span style={{ fontSize: "2em" }} className="title">BOOK NOW</span>
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12" md="6" lg="4" className="px-4">
            <div className="d-flex justify-content-center">
              <MDBCard className="w-100">
                <MDBCardHeader style={{ fontSize: "2em" }} className="cardHeaderBlue text-center p-0">
                  E-Workbook
                </MDBCardHeader>
                <MDBCardBody className="light-grey-bg homeCardBody">
                  <h5 className="text-secondary text-center">Our workbook will provide a "how-to" for discovering your values, envisioning outcomes, and making sense of how to use both in decision making</h5>
                  <p className="text-info text-center"><strong>Coming Soon</strong></p>
                </MDBCardBody>
                <img alt="workshop" src={workbook} className="w-100" />
              </MDBCard>
            </div>
            <MDBBtn onClick={goToContact} className="px-0 py-1 mx-0 w-100 border border-secondary">
              <span style={{ fontSize: "2em" }} className="title">BUY NOW</span>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow center className="mt-2">
          <MDBCol size="12" md="6" lg="4" className="px-4">
            <div className="d-flex justify-content-center">
              <MDBCard className="w-100">
                <MDBCardHeader style={{ fontSize: "2em" }} className="cardHeaderBlue text-center p-0">
                  ValuCards™
                </MDBCardHeader>
                <MDBCardBody className="light-grey-bg homeCardBody">
                  <h5 className="text-secondary text-center">The ValuCards™ can help people, families, teams, and organizations explore what is most important. The ValuCards™ include</h5>
                  <ul className="text-secondary mb-0">
                    <li style={{ fontSize: "0.9em" }}>The value definition</li>
                    <li style={{ fontSize: "0.9em" }}>Questions about the value for each decision option, and</li>
                    <li style={{ fontSize: "0.9em" }}>Behaviors of people who prioritize that value</li>
                  </ul>
                  <p className="text-info text-center"><strong>$20 Cards<br/>$10 PDF</strong></p>
                </MDBCardBody>
                <img alt="workshop" src={comingsoon} className="w-100" />
              </MDBCard>
            </div>
            <MDBBtn onClick={goToContact} className="px-0 py-1 mx-0 w-100 border border-secondary">
              <span style={{ fontSize: "2em" }} className="title">GET MORE INFO</span>
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12" md="6" lg="4" className="px-4">
            <div className="d-flex justify-content-center">
              <MDBCard className="w-100">
                <MDBCardHeader style={{ fontSize: "2em" }} className="cardHeaderBlue text-center p-0">
                  VC Subscription
                </MDBCardHeader>
                <MDBCardBody className="light-grey-bg homeCardBody">
                  <h5 className="text-secondary text-center">Subscribe to our online portal and adapt it to your practice</h5>
                  <p className="text-info text-center"><strong>Coming Soon</strong></p>
                </MDBCardBody>
                <img alt="workshop" src={comingsoon} className="w-100" />
              </MDBCard>
            </div>
            <MDBBtn onClick={goToDemo} className="px-0 py-1 mx-0 w-100 border border-secondary">
              <span style={{ fontSize: "2em" }} className="title">Demo</span>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default withRouter(Home);