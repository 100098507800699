import React, { useState, FunctionComponent, useCallback, useMemo } from 'react';
import { useAppContext, Stage } from '../../AppContext';
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBInput, MDBBtn, MDBCollapse, MDBIcon } from 'mdbreact';
import { values } from '../../content/values';
import ToggleCard from '../ToggleCard';
import next_active from "../../assets/img/next_active.png";
import next_inactive from "../../assets/img/next_inactive.png";

interface Props {
  collapsed?: boolean;
}

const Step1A: FunctionComponent<Props> = ({ collapsed = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!collapsed);

  const [customValue, setCustomValue] = useState<string>("");
  const { appContext, dispatch } = useAppContext();
  const { allValues, initialSelection } = appContext;

  const setAllValues = useCallback((arg: Array<string> | ((arg0: string[]) => string[])) => {
    dispatch({
      type: "allValues",
      payload: arg
    });
  }, [dispatch]);
  const setStage = useCallback((arg: Stage) => {
    dispatch({
      type: "stage",
      payload: arg
    });
  }, [dispatch]);
  const setInitialSelection = useCallback((arg: Array<string> | ((arg0: string[]) => string[])) => {
    dispatch({
      type: "initialSelection",
      payload: arg
    });
  }, [dispatch]);
  const setFinalSelection = useCallback((arg: Array<string> | ((arg0: string[]) => string[])) => {
    dispatch({
      type: "finalSelection",
      payload: arg
    });
  }, [dispatch]);

  const cards = allValues.map((value, index) => {
    const description = values.find((fullValue) => value === fullValue.name)?.description;
    return (
      <MDBCol key={value + index} md="6" lg="4" xl="3" size="12" className="mt-2">
        <ToggleCard
          description={description}
          toggled={initialSelection.find((item) => item === value) !== undefined}
          title={value}
          onToggle={(toggle: boolean) => {
            if (toggle) {
              setInitialSelection(prev => [...prev, value]);
            } else {
              setInitialSelection(prev => prev.filter((item) => item !== value));
              setFinalSelection(prev => prev.filter((item) => item !== value));
            }
          }} />
      </MDBCol>
    );
  });

  const toggleBtn = useMemo(() => {
    return (
      <MDBBtn
        onClick={() => setIsOpen(prev => !prev)}
        color="info"
        className="round-btn-icon p-0 m-0"
        size="sm"
      >
        {isOpen ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />}
      </MDBBtn>
    );
  }, [isOpen]);

  const addCustomValue = useCallback(() => {
    if (customValue.length > 0) {
      setAllValues(prev => [...prev, customValue]);
      setInitialSelection(prev => [...prev, customValue]);
      setCustomValue("");
    }
  }, [customValue, setAllValues, setInitialSelection]);

  const nextBtn = useMemo(() => {
    if (initialSelection.length) {
      return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step1B)}>
        <img alt="next" height="25em" src={next_active} />
      </div>
    } else {
      return <div className="m-5">
        <img alt="next" height="25em" src={next_inactive} />
      </div>
    }
  }, [initialSelection, setStage]);

  return (
    <MDBContainer className="mt-5">
      {toggleBtn} <span className="title align-middle"> Step 1a.</span> {isOpen && <span className="title align-middle" >Choose your values.</span>}
      <MDBCollapse isOpen={isOpen}>
        <p style={{ paddingLeft: "82px" }} className="text-secondary">
          Ask yourself "Which of the following are priorities for me?"
          <ul>
            <li>Personal qualities I want to develop</li>
            <li>Traits I value in others</li>
            <li>Qualities I value in society</li>
          </ul>
        </p>
        <MDBCard className="rounded exercise-card-bg border border-secondary">
          <MDBCardBody>
            <p className="text-secondary">Choose values from the list below. You can also insert other values by typing in the blank box, then click the <MDBIcon icon="plus-circle"/> sign.</p>
            <MDBRow>
              {cards}
            </MDBRow>
            <MDBRow center>
              <MDBCol md="6" lg="4" xl="3" size="12" className="mt-2">
                <MDBCard className="border border-secondary">
                  <div className="align-middle">
                    <MDBInput
                      style={{ width: "80%" }}
                      containerClass="d-inline m-0 p-0"
                      className="d-inline pl-2 mx-2"
                      type="text"
                      value={customValue}
                      getValue={(value) => setCustomValue(value.toString())}
                    />
                    <MDBIcon onClick={addCustomValue} icon="plus-circle" />
                  </div>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <MDBRow end>
          {nextBtn}
        </MDBRow>
      </MDBCollapse>
    </MDBContainer>
  );
}

export default Step1A;