import React, { FunctionComponent } from 'react';
import { MDBContainer } from 'mdbreact';

interface Props { }

const Videos: FunctionComponent<Props> = (props) => {

  return (
    <>
      <MDBContainer fluid className="mt-5 pt-5">
        <div className="mt-1 pt-1">
          <iframe 
            width="560" 
            height="315" 
            className="mw-100"
            src="https://www.youtube.com/embed/-uVBS069550" 
            title="Valucision Training Video Part 1" 
            frameBorder="0" 
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
          ></iframe>
        </div>
        <div className="mt-1 pt-1">
          <iframe 
            width="560" 
            height="315" 
            className="mw-100"
            src="https://www.youtube.com/embed/9LEmiZs2iP0" 
            title="Valucision Training Video Part 2" 
            frameBorder="0" 
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
          ></iframe>
          </div>
      </MDBContainer>
    </>
  );
}

export default Videos;