import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import logo from './assets/img/VCLogo_web.png';
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdbreact';
import { useLocation, withRouter, RouteComponentProps } from 'react-router-dom';
import { useAppContext } from './AppContext';
import { Auth, Hub } from 'aws-amplify';
import './App.css';
import SignInModal from './components/security/SignInModal';
import ForgotPasswordModal from './components/security/ForgotPasswordModal';
import ChangePasswordModal from './components/security/ChangePasswordModal';

interface Props extends RouteComponentProps { }

const Navbar: FunctionComponent<Props> = ({ history }) => {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const [showSignInModal, setShowSignInModal] = useState<boolean>(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const toggleNavOpen = () => setNavOpen((prev: boolean) => !prev);
  const { appContext, dispatch } = useAppContext();
  const { activeTab, email } = appContext;

  const toggleSignInModal = useCallback(() => {
    setShowSignInModal(prev => !prev);
  }, [setShowSignInModal]);

  const toggleForgotPasswordModal = useCallback(() => {
    setShowForgotPasswordModal(prev => !prev);
  }, [setShowForgotPasswordModal]);
  
  const toggleChangePasswordModal = useCallback(() => {
    setShowChangePasswordModal(prev => !prev);
  }, [setShowChangePasswordModal]);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          if (data.challengeParam?.userAttributes?.email) {
            dispatch({
              type: "email",
              payload: data.challengeParam.userAttributes.email,
            });
          } else {
            dispatch({
              type: "email",
              payload: data.attributes.email,
            });
          }
          break;
        case "signOut":
          dispatch({
            type: "email",
            payload: null,
          });
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(user => dispatch({
        type: "email",
        payload: user.attributes.email,
      }))
      .catch(() => console.error("Not signed in"));
  }, [dispatch]);


  const location = useLocation();

  useEffect(() => {
    dispatch({
      type: "activeTab",
      payload: location.pathname,
    })
  }, [location, dispatch]);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error("error signing out:", error);
    }
  }

  const forgotPassword = useCallback(() => {
    setShowSignInModal(false);
    setShowForgotPasswordModal(true);
  }, [setShowSignInModal, setShowForgotPasswordModal]);

  return (
    <MDBNavbar color="white" light expand="lg" fixed="top">
      <MDBNavLink to="/" onClick={() => setNavOpen(false)}>
        <img alt="logo" src={logo} height="48" />
      </MDBNavLink>
      <MDBNavbarToggler onClick={toggleNavOpen} />
      <MDBCollapse isOpen={navOpen} navbar>
        <MDBNavbarNav right>
          <MDBNavItem className="px-3">
            <MDBNavLink className={activeTab === "/about" ? "active-tab" : undefined} to="/about">
              <span className={activeTab === "/about" ? "active-tab-text" : "inactive-tab-text"}>
                Our Story
              </span>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className="px-3">
            <MDBNavLink className={activeTab === "/faq" ? "active-tab" : undefined} to="/faq">
              <span className={activeTab === "/faq" ? "active-tab-text" : "inactive-tab-text"}>
                FAQ
              </span>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem
            className={(activeTab === "/categories" || activeTab === "/cards" || activeTab === "/exercise")
              ? "active-tab px-3"
              : "px-3"}
          >
            <MDBDropdown>
              <MDBDropdownToggle nav>
                <span className={(activeTab === "/categories" || activeTab === "/cards" || activeTab === "/exercise" || activeTab == "/videos") ? "active-tab-text" : "inactive-tab-text"}>
                  VC Exercise
                </span>
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem onClick={() => history.push('/categories')}>
                  <span className={activeTab === "/categories" ? "active-tab-text px-3 inactive-tab-text" : "px-3 inactive-tab-text"}>
                    Value Categories
                  </span>
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => history.push('/cards')}>
                  <span className={activeTab === "/cards" ? "active-tab-text px-3 inactive-tab-text" : "px-3 inactive-tab-text"}>
                    ValuCards™
                  </span>
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => history.push('/exercise')}>
                  <span className={activeTab === "/exercise" ? "active-tab-text px-3 inactive-tab-text" : "px-3 inactive-tab-text"}>
                    VC Exercise Demo
                  </span>
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => history.push('/videos')}>
                  <span className={activeTab === "/videos" ? "active-tab-text px-3 inactive-tab-text" : "px-3 inactive-tab-text"}>
                    Video Tutorials
                  </span>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavItem>
          <MDBNavItem className="px-3">
            <MDBNavLink className={activeTab === "/printouts" ? "active-tab" : undefined} to="/printouts">
              <span className={activeTab === "/printouts" ? "active-tab-text" : "inactive-tab-text"}>
                Printouts
              </span>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className="px-3">
            <MDBNavLink className={activeTab === "/contact" ? "active-tab" : undefined} to="/contact">
              <span className={activeTab === "/contact" ? "active-tab-text" : "inactive-tab-text"}>
                Contact Us
              </span>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className="px-3">
            <MDBBtn
              onClick={() => history.push('/exercise')}
              color="default"
              className="p-1 border border-secondary m-0"
              size="sm"
            >
              <span className="title px-5 py-1" style={{ fontSize: "2em" }}>Demo</span>
            </MDBBtn>
          </MDBNavItem>
          <MDBNavItem className="px-3">
            <MDBDropdown>
              <MDBDropdownToggle nav className="px-2 py-1" >
                <MDBIcon icon="user-circle" size="2x" />
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                {
                  email ? (
                    <>
                      <MDBDropdownItem disabled><span>{email}</span></MDBDropdownItem>
                      <MDBDropdownItem onClick={toggleChangePasswordModal}><span>Change Password</span></MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem onClick={signOut}>Logout</MDBDropdownItem>
                    </>
                  ) : (
                    <>
                      <MDBDropdownItem onClick={toggleSignInModal}>Login</MDBDropdownItem>
                    </>
                  )
                }
              </MDBDropdownMenu>
            </MDBDropdown>
            <SignInModal toggle={toggleSignInModal} isOpen={showSignInModal} forgotPassword={forgotPassword}/>
            <ForgotPasswordModal toggle={toggleForgotPasswordModal} isOpen={showForgotPasswordModal} />
            <ChangePasswordModal toggle={toggleChangePasswordModal} isOpen={showChangePasswordModal} />
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
}

export default withRouter(Navbar);
