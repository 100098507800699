import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
} from 'mdbreact';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  forgotPassword: () => void;
}

const SignInModal: FunctionComponent<Props> = ({
  isOpen,
  toggle,
  forgotPassword,
}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorLabel, setErrorLabel] = useState<string>('');

  const [needsNewPassword, setNeedsNewPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [user, setUser] = useState<any>();

  useEffect(() => {
    setEmail("");
    setPassword("");
    setErrorLabel("");
    setNeedsNewPassword(false);
    setNewPassword("");
    setUser(undefined);
    setRequestPending(false);
  }, [isOpen]);

  const signIn = useCallback(() => {
    setRequestPending(true);
    Auth.signIn(email, password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setErrorLabel("Choose a new password");
          setNeedsNewPassword(true);
          setUser(user);
        } else {
          toggle();
        }
      }).catch(error => {
        if (error.code === "NotAuthorizedException" || error.code === "UserNotFoundException") {
          setErrorLabel("Email or password is incorrect");
        } else if (error.code === "PasswordResetRequiredException") {
          setErrorLabel("Password Reset Required. Reset your password with the Forgot Password tool.");
        } else if (error.code === "LimitExceededException") {
          setErrorLabel("Too many requests. Try again later.");
        } else {
          setErrorLabel("Something went wrong");
        }
        console.error(error);
      })
      .finally(() => {
        setRequestPending(false);
      });
  }, [email, password, toggle]);

  const changePassword = useCallback(() => {
    if (newPassword.length < 8) {
      setErrorLabel("Choose a new password. Password must be at least 8 characters.");
      return;
    }
    setRequestPending(true);
    Auth.completeNewPassword(user, newPassword, {})
    .then(() => {
      toggle();
    })
    .catch(error => {
      if (error.code === "LimitExceededException") {
        setErrorLabel("Too many requests. Try again later.");
      } else {
        setErrorLabel("Something went wrong");
      }
      console.error(error);
    })
    .finally(() => {
      setRequestPending(false);
    });
  }, [user, newPassword, toggle]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MDBModal isOpen={isOpen} toggle={toggle} size="lg" centered
        inline={false}
        noClickableBodyWithoutBackdrop={false}
        overflowScroll={false}>
        <MDBModalBody>
          <p className="h5 text-center mb-4">Sign in</p>
          {errorLabel && <p className="red-text text-center">{errorLabel}</p>}
          {
            needsNewPassword ? (
              <>
                <MDBInput
                  label="New Password"
                  icon="lock"
                  group
                  type="password"
                  value={newPassword}
                  getValue={(pw) => setNewPassword(pw.toString())} />
                <div className="text-center">
                  <MDBBtn
                    color="primary"
                    disabled={requestPending}
                    onClick={changePassword}>Change Password</MDBBtn>
                </div>
              </>
            ) : (
              <>
                <div className="grey-text">
                  <MDBInput
                    label="Email"
                    icon="envelope"
                    group
                    type="email"
                    value={email}
                    getValue={(email) => setEmail(email.toString())} />
                  <MDBInput
                    label="Password"
                    icon="lock"
                    group
                    type="password"
                    value={password}
                    getValue={(pw) => setPassword(pw.toString())} />
                </div>
                <div className="text-right pb-2">
                  <Link to="#" onClick={forgotPassword}>Forgot Password</Link>
                </div>
                <div className="text-center">
                  <MDBBtn
                    color="light"
                    disabled={requestPending}
                    onClick={toggle}>Cancel</MDBBtn>
                  <MDBBtn
                    color="primary"
                    disabled={requestPending}
                    onClick={signIn}>Login</MDBBtn>
                </div>
              </>
            )
          }
        </MDBModalBody>
      </MDBModal>
    </div>
  );
};

export default SignInModal;
