import { MDBCol, MDBRow } from "mdbreact";
import React, { FunctionComponent } from "react";
import { values } from "../content/values";
import ToggleCard from "./ToggleCard";

interface Props {
  selectedValues: string[];
  setSelectedValues: (func: (selectedValues: string[]) => string[]) => void;
  maxSelected?: number;
}

const ValuesListPicker: FunctionComponent<Props> = ({ selectedValues, setSelectedValues, maxSelected = 0 }) => {
  const cards = values.map((valueFull, index) => {
    const value = valueFull.name;
    return (
      <MDBCol key={value + index} md="6" lg="4" xl="3" size="12" className="mt-2">
        <ToggleCard
          toggled={selectedValues.find((item) => item === value) !== undefined}
          title={value}
          onToggle={(toggle: boolean) => {
            if (toggle) {
              if (maxSelected <= 0 || selectedValues.length < maxSelected) {
                setSelectedValues(prev => [...prev, value]);
              }
            } else {
              setSelectedValues(prev => prev.filter((item) => item !== value));
            }
          }} />
      </MDBCol>
    );
  });

  return (
    <MDBRow>
      {cards}
    </MDBRow>
  );
}

export default ValuesListPicker;