import React from 'react';
import { category } from '../views/Categories';
import ValueTooltip from '../components/ValueTooltip';


export const categories: Array<category> = [
  {
    category: "Achievement",
    content: (
      <div>
        <p>Values in this category are based on achieving a goal or ideal. Goals should be chosen through careful consideration. Achievement values are motivated by what you care about.</p>
        <p>Values in this category include Accomplishment, <ValueTooltip value="Achievement" />, Competence, Determination, Diligence, Fortitude, Industriousness, Persistence, Professionalism, <ValueTooltip value="Reputation" />, <ValueTooltip value="Respect" />, Skillfulness, ...</p>
      </div>
    )
  },
  {
    category: "Care for Others",
    content: (
      <div>
        <p>Values in this category are based on doing what is necessary to help, protect, or look after someone. Caring often involves dedicating yourself to people and putting your own needs aside. </p>
        <p>Values in this category include Acceptance, <ValueTooltip value="Care for Others" />, Comfort, Compassion, Empathy, <ValueTooltip value="Family" />, Forgiveness, Happiness, Love, Understanding, …</p>
      </div>
    )
  },
  {
    category: "Courage",
    content: (
      <div>
        <p>Values in this category are based on acting unselfishly for a good cause. Courage means freely choosing to risk personal well-being for the good of others or for an ideal. Moral courage involves acting morally in the face of popular opposition, shame, scandal, discouragement, or personal loss. </p>
        <p>Values in this category include Bravery, Conviction, <ValueTooltip value="Courage" />, Fearlessness, Valor, … </p>
      </div>
    )
  },
  {
    category: "Creativity",
    content: (
      <div>
        <p>Values in this category are based on using the imagination, that is “thinking out of the box”. Creativity is often associated with the visual arts or music, but throughout history, creative insights have resulted in scientific breakthroughs, technological inventions, and everyday problem-solving.</p>
        <p>Values in this category include <ValueTooltip value="Creativity" />, Discovery, Exploration, Expressiveness, Imagination, Innovation, Inquisitiveness, Intuitiveness, Originality, …</p>
      </div>
    )
  },
  {
    category: "Enjoyment",
    content: (
      <div>
        <p>Enjoyment involves the thoughtful appreciation of something such as experiencing a generous act or beautiful sunset. Enjoyment evokes human emotion or makes a personal connection through human relationships. This type of enjoyment is different from the emotional/physical high achieved in reaction to a physical stimulation from drugs or pornography. This distinction is important when making life decisions. </p>
        <p>Values in this category include Appreciation, Beauty, Enthusiasm, Fun, Joy, Gratitude, <ValueTooltip value="Pleasure" />, …</p>
      </div>
    )
  },
  {
    category: "Faith",
    content: (
      <div>
        <p>Values in this category are based on trust in relationships. Faith is the reasonable trust that something is true based on the authority of the person speaking, even if it cannot be verified. Theologically, faith enables a person to believe that what God has revealed is true. In secular society we may have faith in an authority we consider credible. Faith is not opposed to reason. They work in harmony to reveal greater understanding. </p>
        <p>Values in this category include Allegiance, Acceptance, Belief, <ValueTooltip value="Faith" />, Fidelity, <ValueTooltip value="Common Good" />, Community, Devotion, Doctrine, Ethics, <ValueTooltip value="Fairness" />, Hope, Spirituality, …</p>
      </div>
    )
  },
  {
    category: "Freedom",
    content: (
      <div>
        <p>Values in this category are based on living your life as you choose or following your own path while considering the common good. Freedom in this category shouldn’t be confused with doing what you want without any restraint, often leading to a negative outcome for others. These freedom values lead to more positive outcomes.</p>
        <p>Values in the category include <ValueTooltip value="Independence" />, Autonomy, Self-determination, Liberty, Democracy, …</p>
      </div>
    )
  },
  {
    category: "Health",
    content: (
      <div>
        <p>Values in this category are based on physical and mental well-being, as well as spiritual wellness. More broadly health can include an attitude of happiness or a healthy society.</p>
        <p>Values in this category include Energy, Fitness, Good condition, <ValueTooltip value="Health" />, Love, Peace, Robustness, Vitality, Well-being, Wholeness…  </p>
      </div>
    )
  },
  {
    category: "Integrity",
    content: (
      <div>
        <p>Values in this category are based on being and doing what is just, lawful, or moral. They join your feelings, attitudes, and personality traits together with upright actions. Values in this category are principled and honest and people follow them even when no one is looking.</p>
        <p>Values in this category include Accountability, Commitment, Dependability, Dignity, <ValueTooltip value="Honesty" />, Honor, Justice, Loyalty, Patience, <ValueTooltip value="Responsibility" />, Trustworthiness, ...</p>
      </div>
    )
  },
  {
    category: "Intelligence",
    content: (
      <div>
        <p>Values in this category focus on learning from experience and using reason to solve problems and direct your conduct, both personally or in community. We use our intelligence to develop skills and make plans.</p>
        <p>Values in this category include Awareness, Cleverness, Common Sense, Decisiveness, Foresightedness, Insightfulness, <ValueTooltip value="Intelligence" />, Knowledge, Prudence, … </p>
      </div>
    )
  },
  {
    category: "Order",
    content: (
      <div>
        <p>Values in this category are based on logical procedures or standards that assist in achieving a goal, task, or daily life satisfaction. They include organizing things, activities, and people, as well as managing time and resources.</p>
        <p>Values in this category include Accuracy, Cleanliness, Consistency, Discipline, Decisiveness, <ValueTooltip value="Financial Stability" />, Lawfulness, <ValueTooltip value="Order" />, <ValueTooltip value="Security" />, …</p>
      </div>
    )
  },
  {
    category: "Strength",
    content: (
      <div>
        <p>Values in this category are based on using your energy or will for good or to overcome opposition. It can also refer to the latent capacity to achieve or bring things to a successful conclusion. Strength is different from stubbornness, which is a refusal to yield based on one’s own ideas. </p>
        <p>Values in this category include Ambition (moderated), Assertiveness, Boldness, Confidence, Dedication, Fortitude, Grit, Perseverance, <ValueTooltip value="Strength" />, Tenacity, … </p>
      </div>
    )
  }
];

