import React, { FunctionComponent } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBAnimation } from 'mdbreact';
import { categories } from '../content/categories'
import DropdownCard from '../components/DropdownCard';

interface Props {

}

export interface category {
  category: string;
  content: JSX.Element;
}

const Categories: FunctionComponent<Props> = (props) => {
  const cards = categories.map((category, index) => {
    return (
      <MDBCol key={category.category + index} size="12" className="mb-4">
        <MDBAnimation type="zoomInUp" delay={`${index * 100}ms`}>
          <DropdownCard title={category.category}>
            {category.content}
          </DropdownCard>
        </MDBAnimation>
      </MDBCol>
    );
  });

  return (
    <MDBContainer className="mt-5 pt-5">
      <MDBRow className="mt-5">
        <MDBCol size="12" md="4">
          <h3 className="title">Values By Category</h3>
          <p>
            Didn’t find what you were looking for in the values exercise? Here is a list of values by category so you can see how values are rated and are similar. The category definitions can also help you prioritize your values.
          </p>
        </MDBCol>
        <MDBCol size="12" md="8">
          <MDBRow>
            {cards}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}


export default Categories;