import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
} from 'mdbreact';
import { Auth } from 'aws-amplify';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

const ForgotPasswordModal: FunctionComponent<Props> = ({
  isOpen,
  toggle,
}) => {
  const [email, setEmail] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showEnterCode, setShowEnterCode] = useState<boolean>(false);
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorLabel, setErrorLabel] = useState<string>('');

  useEffect(() => {
    setEmail("");
    setVerificationCode("");
    setPassword("");
    setErrorLabel("");
    setRequestPending(false);
    setShowEnterCode(false);
  }, [isOpen]);

  const forgotPassword = useCallback(() => {
    setRequestPending(true);
    Auth.forgotPassword(email)
      .then(data => {
        setErrorLabel("We have sent you a verification code. Please enter it and select a new password.");
        setShowEnterCode(true);
      }).catch(error => {
        setErrorLabel("");
        if (error.code === "UserNotFoundException") {
          setErrorLabel("Email not recognized");
        } else if (error.code === "LimitExceededException") {
          setErrorLabel("Too many requests. Try again later.");
        } else {
          setErrorLabel("Something went wrong");
        }
        console.error(error);
      })
      .finally(() => {
        setRequestPending(false);
      });
  }, [email]);

  const changePassword = useCallback(() => {
    if (password.length < 8) {
      setErrorLabel("Choose a new password. Password must be at least 8 characters.");
      return;
    }
    setRequestPending(true);
    Auth.forgotPasswordSubmit(email, verificationCode, password)
    .then(() => {
      Auth.signIn(email, password);
      toggle();
    })
    .catch(error => {
      if (error.code === "CodeMismatchException") {
        setErrorLabel("Incorrect Verification Code");
        setVerificationCode("");
      } else if (error.code === "LimitExceededException") {
        setErrorLabel("Too many requests. Try again later.");
      } else {
        setErrorLabel("Something went wrong");
      }
      console.error(error);
    })
    .finally(() => {
      setRequestPending(false);
    });
  }, [email, password, verificationCode, toggle]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MDBModal isOpen={isOpen} toggle={toggle} size="lg" centered
        inline={false}
        noClickableBodyWithoutBackdrop={false}
        overflowScroll={false}>
        <MDBModalBody>
          <p className="h5 text-center mb-4">Forgot Password</p>
          {errorLabel && <p className="red-text text-center">{errorLabel}</p>}
          {
            showEnterCode ? (
              <>
                <MDBInput
                  label="Verification Code"
                  icon="hashtag"
                  group
                  type="text"
                  value={verificationCode}
                  getValue={(vc) => setVerificationCode(vc.toString())} />
                <MDBInput
                  label="New Password"
                  icon="lock"
                  group
                  type="password"
                  value={password}
                  getValue={(pw) => setPassword(pw.toString())} />
                <div className="text-center">
                  <MDBBtn
                    color="primary"
                    disabled={requestPending}
                    onClick={changePassword}>Reset Password</MDBBtn>
                </div>
              </>
            ) : (
              <>
                <div className="grey-text">
                  <MDBInput
                    label="Email"
                    icon="envelope"
                    group
                    type="email"
                    value={email}
                    getValue={(email) => setEmail(email.toString())} />
                </div>
                <div className="text-center">
                  <MDBBtn
                    color="light"
                    disabled={requestPending}
                    onClick={toggle}>Cancel</MDBBtn>
                  <MDBBtn
                    color="primary"
                    disabled={requestPending}
                    onClick={forgotPassword}>Send Verification Code</MDBBtn>
                </div>
              </>
            )
          }
          
        </MDBModalBody>
      </MDBModal>
    </div>
  );
};

export default ForgotPasswordModal;
