import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
} from 'mdbreact';
import { Auth } from 'aws-amplify';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

const ChangePasswordModal: FunctionComponent<Props> = ({
  isOpen,
  toggle,
}) => {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorLabel, setErrorLabel] = useState<string>('');

  useEffect(() => {
    setOldPassword("");
    setNewPassword("");
    setErrorLabel("");
    setRequestPending(false);
  }, [isOpen]);

  const changePassword = useCallback(() => {
    if (newPassword.length < 8) {
      setErrorLabel("Choose a new password. Password must be at least 8 characters.");
      return;
    }
    setRequestPending(true);
    Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, oldPassword, newPassword).then(() => toggle());
    })
    .catch(error => {
      if (error.code === "NotAuthorizedException") {
        setErrorLabel("Password is incorrect for current user");
      } else if (error.code === "LimitExceededException") {
        setErrorLabel("Too many requests. Try again later.");
      } else {
        setErrorLabel("Something went wrong");
      }
      console.error(error);
    })
    .finally(() => {
      setRequestPending(false);
    });
  }, [newPassword, oldPassword, toggle]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MDBModal isOpen={isOpen} toggle={toggle} size="lg" centered
        inline={false}
        noClickableBodyWithoutBackdrop={false}
        overflowScroll={false}>
        <MDBModalBody>
          <p className="h5 text-center mb-4">Change Password</p>
          {errorLabel && <p className="red-text text-center">{errorLabel}</p>}
          <MDBInput
            label="Old Password"
            icon="lock"
            group
            type="password"
            value={oldPassword}
            getValue={(opw) => setOldPassword(opw.toString())} />
          <MDBInput
            label="New Password"
            icon="lock"
            group
            type="password"
            value={newPassword}
            getValue={(pw) => setNewPassword(pw.toString())} />
          <div className="text-center">
            <MDBBtn
              color="light"
              disabled={requestPending}
              onClick={toggle}>Cancel</MDBBtn>
            <MDBBtn
              color="primary"
              disabled={requestPending}
              onClick={changePassword}>Change Password</MDBBtn>
          </div>
        </MDBModalBody>
      </MDBModal>
    </div>
  );
};

export default ChangePasswordModal;
