import React, { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useAppContext, Stage } from '../../AppContext';
import { MDBCol, MDBBtn, MDBIcon, MDBCollapse, MDBCard, MDBCardBody, MDBRow, MDBInput } from 'mdbreact';
import next_active from "../../assets/img/next_active.png";
import prev_active from "../../assets/img/prev_active.png";
import next_inactive from "../../assets/img/next_inactive.png";
import Step1B from './Step1B';

interface Props {
  collapsed?: boolean;
}

const Step2A: FunctionComponent<Props> = ({ collapsed = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!collapsed);

  const { appContext, dispatch } = useAppContext();
  const { bigDecision, peopleInvolved } = appContext;

  const setStage = useCallback((arg: Stage) => {
    dispatch({
      type: "stage",
      payload: arg
    });
  }, [dispatch]);
  const setBigDecision = useCallback((arg: string) => {
    dispatch({
      type: "bigDecision",
      payload: arg
    });
  }, [dispatch]);
  const setPeopleInvolved = useCallback((arg: string) => {
    dispatch({
      type: "peopleInvolved",
      payload: arg
    });
  }, [dispatch]);

  const toggleBtn = useMemo(() => {
    return (
      <MDBBtn
        onClick={() => setIsOpen(prev => !prev)}
        color="info"
        className="round-btn-icon p-0 m-0"
        size="sm"
      >
        {isOpen ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />}
      </MDBBtn>
    );
  }, [isOpen]);

  const nextBtn = useMemo(() => {
    if (bigDecision.length) {
      return <span className="float-right next-btn" onClick={() => setStage(Stage.Step2B)}>
        <img alt="next" height="25em" src={next_active} />
      </span>
    } else {
      return <span className="float-right">
        <img alt="next" height="25em" src={next_inactive} />
      </span>
    }
  }, [bigDecision, setStage]);

  const prevBtn = useMemo(() => {
    return <div className="next-btn" onClick={() => setStage(Stage.Step1B)}>
      <img alt="next" height="25em" src={prev_active} />
    </div>
  }, [setStage]);

  return (
    <div className="mt-5">
      <Step1B done collapsed={collapsed} />
      <div className="mt-5">
        {toggleBtn} <span className="title align-middle">Step 2. </span> {isOpen && <span className="title align-middle">Work On Your Big Decision</span>}
        <MDBCollapse isOpen={isOpen}>
          <MDBCard className="rounded exercise-card-bg border border-secondary mt-3">
            <MDBCardBody>
              <MDBRow middle>
                <MDBCol size="2">
                  {prevBtn}
                </MDBCol>
                <MDBCol size="8">
                  <MDBRow middle>
                    <MDBCol size="12" md="4">
                      <strong className="text-secondary">My <span className="text-info">Big</span> Decision is...</strong>
                    </MDBCol>
                    <MDBCol size="12" md="8">
                      <MDBInput
                        type="text"
                        containerClass="m-0 p-0 d-inline"
                        className="m-0 p-0 d-inline w-100"
                        value={bigDecision}
                        getValue={value => setBigDecision(value.toString())}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow middle>
                    <MDBCol size="12" md="4">
                      <strong className="text-secondary">The people involved are...</strong>
                    </MDBCol>
                    <MDBCol size="12" md="8">
                      <MDBInput
                        type="text"
                        containerClass="m-0 p-0 d-inline"
                        className="m-0 p-0 d-inline w-100"
                        value={peopleInvolved}
                        getValue={value => setPeopleInvolved(value.toString())}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol size="2">
                  {nextBtn}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCollapse>
      </div>
    </div>
  );
}

export default Step2A;