import React from 'react';
import values_box from "../assets/img/values_box.png"
import { faq } from "../views/FAQs";
import { Link } from 'react-router-dom';

export const faqs: Array<faq> = [
  {
    question: "1. What is Valucision?",
    answer: (
      <div>
        <p>Valucision is a simple counseling technique for people making life-changing decisions. Your clients will gain confidence they are making their best choice! Better decisions. Better outcomes.</p>
        <p>
          In the Valucision training, we will coach you on how to help clients to
          <ul>
            <li>Decide their core values</li>
            <li>Imagine their decision outcomes</li>
            <li>Link their values and outcomes, and</li>
            <li>Make a plan for moving forward.</li>
          </ul>
        </p>
        <p>Valucision provides a new way to approach life-change counseling. It inspires decision-making confidence, prompts instant insight, and provides a path forward. Valucision gives clients a place to record their choices and revisit them electronically to update their decisions. It also gives counselors a powerful tool for eCounseling. </p>
      </div>
    ),
  },
  {
    question: "2. How do your clients benefit from Valucision?",
    answer: (
      <div>
        <p>We understand the frustration counselors go through when clients are resistant to change or are indecisive. You can use the Valucision technique to get clients to make better decisions, enact healthy changes, and realize better outcomes.</p>
        <p>
          Clients miss appointments when:
          <ul>
            <li>they think they will disappoint their counselor</li>
            <li>their schedules are too busy, or</li>
            <li>they are unsure the therapy is helping.</li>
          </ul>
        </p>
        <p>
          <strong>Here is how Valucision benefits clients:</strong><br />
          <ul>
            <li>Prompts insight</li>
            <li>Provides an online version for eCounseling; no office visits needed</li>
            <li>Requires no prior preparation or paperwork</li>
            <li>Contributes to self-knowledge</li>
            <li>Shows progress toward goals</li>
          </ul>
        </p>
        <p>
          <strong>Testimonials</strong><br />
          We have tested the Valucision technique on more than 45 women making relationship decisions. They all highly recommended the technique. Here are some testimonials:
          <ul>
            <li>"The exercise is really good and a great way to plan a healthy future!" AP, 23, female (2019)</li>
            <li>"Great interactive worksheet! It was an excellent exercise!" TT, 22, female (2019)</li>
            <li>"I liked placing values in order of importance and asking myself why they are placed in that order and how it relates to my decision making." DM, 22, female (2019)</li>
            <li>"The exercise was very helpful. I wouldn’t change anything!" DR, 20, female (2020)</li>
            <li>"It gave me a lot to think about and I feel it will help me!" JS, 34, female (2020)</li>
          </ul>
        </p>
      </div>
    ),
  },
  {
    question: "3. What are best use cases for Valucision?",
    answer: (
      <div>
        <p><strong>If your clients are asking questions like…</strong></p>
        <p>My marriage is in trouble. Should I consider a divorce or save the relationship?</p>
        <p>I’m pregnant. Should I consider the abortion pill or have the baby? How will that affect my health? </p>
        <p>What should I do about my current relationship?</p>
        <p>I’m unhappy with my lifestyle. How can I change it?</p>
        <p>Where should I go to college?</p>
        <p>Where should I retire?</p>
        <p><strong>Valucision can help your clients gain confidence they are making their best choice!</strong></p>
      </div>
    ),
  },
  {
    question: "4. How do counselors benefit from Valucision?",
    answer: (
      <div>
        <p>
          Here is how Valucision benefits counselors:
          <ul>
            <li>Integrates perfectly in eCounseling or eTherapy</li>
            <li>Positions you for more success, better reviews, and new clients</li>
            <li>Adds a new, dynamic component to your practice, especially for clients making important changes</li>
            <li>Curbs missed appointments from unprepared clients</li>
            <li>Fits nicely into couples, family, or group counseling </li>
            <li>Provides current client insights</li>
            <li>Introduces a plan forward </li>
            <li>Opens areas for further discussion</li>
          </ul>
        </p>
      </div>
    ),
  },
  {
    question: "5. How does the demo work?",
    answer: (
      <div>
        <p>Go to <Link to="/">www.valucision.com</Link> and try the demo to learn how the Valucision technique works. Use an example or a real-life scenario and choose values from our list; imagine outcomes; explore the ValuCards™; and learn how all the tools work together.</p>
      </div>
    ),
  },
  {
    question: "6. How is this different from what counselors are currently doing?",
    answer: (
      <div>
        <p>Counselors may use the "spheres of influence" exercise to get clients to look at areas of their life and see which areas may be impacting or influencing them. Counselors also use the "stages of change" exercise helps prioritize changes the client needs to implement. </p>
        <p>Valucision goes beyond those methods because outcomes show what positive ways of moving forward look like. The Valucision technique also prioritizes current life-changing decisions.</p>
        <p>Valucision provides a new way to approach life-change counseling. It inspires decision-making confidence, prompts instant insight, and provides a path forward. Valucision gives clients a place to record their choices and revisit them electronically to update their decisions. It also gives counselors a powerful tool for eCounseling.</p>
      </div>
    ),
  },
  {
    question: "7. Why are values important in decision making?",
    answer: (
      <div>
        <p>Values are what people believe are important and worthwhile in the way they live. Your clients could be happier if they align their values with their decisions and actions.</p>
        <p>
          Decision making works best when based on what you value. Decisions are the "what" you want to do, and values are the "why." What you value may vary based on
          <ul>
            <li>The type of decision you are making such as life, career, relationships</li>
            <li>The stage of your life, or</li>
            <li>A recent influential event</li>
          </ul>
        </p>
        <img alt="Acting on your values" src={values_box} className="img-fluid" />
        <p>Values are usually stable, but as people move through life, their values may change. Keeping in touch with values is a lifelong exercise. Your clients should continuously revisit their values, especially if they start to feel anxious and can't quite figure out why.</p>
      </div>
    ),
  },
  {
    question: "8. How do we use value categories in the Valucision technique?",
    answer: (
      <div>
        <p>We offer different categories of values because people may use a different set of values for different types of decisions. For example, making ethical and moral decisions such as workplace harassment or divorce often involves different values than choosing a college or where to retire. </p>
        <p>We provide a list of values by category so people can see how values are related and are similar. For instance, trustworthiness includes values such as honesty, integrity, reliability, and loyalty. The category definitions can also help people prioritize their values.</p>
        <p>See <Link to="/categories">Values by Category</Link></p>
      </div>
    ),
  },
  {
    question: "9. How does the Valucision exercise work?",
    answer: (
      <div>
        <ul>
          <li>
            Your clients will begin the Valucision technique by choosing their values. We provide a list of values and their definitions. Your clients will ask themselves “Which of the following are priorities for me?”
            <ul>
              <li>Personal qualities I want to develop</li>
              <li>Traits I value in others</li>
              <li>Qualities I value in society</li>
            </ul>
            Then they can choose their values from the list or fill in their own.
          </li>
          <li>We also provide a list of similar values showing how values are related. Look at the <Link to="/categories">Values Categories</Link> section for more information.</li>
          <li>
            Finally, we provide ValuCards™ for your clients to explore their values more deeply. The ValuCards™ include
          <ul>
              <li>The value definition</li>
              <li>Questions about the value for each decision option, and</li>
              <li>Behaviors of people who prioritize that value</li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: "10. Why use outcomes in decision making?",
    answer: (
      <div>
        <p><strong className="text-center">What are Outcomes?</strong></p>
        <p>People often provide reasons for their choices. Results (outcomes) are different from reasons.</p>
        <p className="ml-4"><b>Reason:</b> I don’t like doing laundry because it takes too much time and is boring.</p>
        <p className="ml-4"><b>Result (Outcome):</b> I have a pile of smelly, dirty laundry on my floor!</p>
        <p>
          Part of good decision making includes identifying the possible outcomes prior to making a choice. Different options have different outcomes.
          <ul>
            <li>Outcomes are what happen after taking an action. </li>
            <li>Imagining outcomes shows what potential choices mean.</li>
            <li>More detailed outcome assessments include listing the positive and negative aspects of a choice and who that choice may affect.</li>
            <li>Some outcomes are changeable; others are not. </li>
          </ul>
        </p>
        <p>Outcomes show positive ways of moving forward and help introduce a path.</p>
      </div>
    ),
  },
  {
    question: "11. What services do we offer? ",
    answer: (
      <div>
        <p>
          In the Valucision training, we will coach you on how to help clients to
          <ul>
            <li>Decide their core values</li>
            <li>Imagine their decision outcomes</li>
            <li>Link their values and outcomes, and</li>
            <li>Make a plan for moving forward.</li>
          </ul>
        </p>
        <p>On this website, you can demo <Link to="/exercise">Valucision</Link> and walk through a real-world decision. </p>
        <p>Then schedule a workshop or online consult with our coaches to learn more about this powerful, online decision-making tool. </p>
      </div>
    ),
  }
];

