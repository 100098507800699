import React, { FunctionComponent } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import diane_jpg from '../assets/img/Diane_Jackson.png';
import cindy_png from '../assets/img/Cindy_Kelly.png';
import girl_in_window from '../assets/img/VC_web_girldoor.png';


interface Props { }

const About: FunctionComponent<Props> = (props) => {

  const referenceLink = "[link]";

  return (
    <>
      <MDBContainer fluid className="about-bg mt-5 pt-5">
        <MDBRow className="mt-5 pt-2" end>
          <MDBCol size="8" md="5">
            <h3 className="title">OUR MISSION</h3>
            <p className="about-body">We provide counselors a technique to get clients to make better decisions, enact healthy changes, and realize better outcomes. </p>
            <p className="about-body">Valucision provides a new way to approach life-change counseling. It inspires decision-making confidence, prompts instant insight, and provides a path forward. Valucision gives clients a place to record their choices and revisit them electronically to update their decisions. It also gives counselors a powerful tool for eCounseling.</p>
          </MDBCol>
          <MDBCol size="4" md="2">
            <img alt="girl in window" src={girl_in_window} className="w-100" />
          </MDBCol>
        </MDBRow>
        <MDBRow className="px-3" end>
          <MDBCol size="12" md="2" className="px-2">
            <span className="title">Testimonial</span>
            <p className="quote">“The exercise is really good and a great way to plan a healthy future!” AP, 23, female (2019)</p>
          </MDBCol>
          <MDBCol size="12" md="2" className="px-2">
            <span className="title">Testimonial</span>
            <p className="quote">“Great interactive worksheet! It was an excellent exercise!” TT, 22, female (2019)</p>
          </MDBCol>
          <MDBCol size="12" md="3" className="px-2">
            <span className="title">Testimonial</span>
            <p className="quote">“I liked placing values in order of importance and asking myself why they are placed in that order and how it relates to my decision making.” DM, 22, female (2019)</p>
          </MDBCol>
          {/* <MDBCol size="12" md="2" className="px-2">
            <span className="title">Testimonial</span>
            <p>“The exercise was very helpful. I wouldn’t change anything!” DR, 20, female (2020)</p>
          </MDBCol>
          <MDBCol size="12" md="2" className="px-2">
            <span className="title">Testimonial</span>
            <p>“It gave me a lot to think about and I feel it will help me!” JS, 34, female (2020)</p>
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className="mt-5">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12" md="6" className="px-5 text-secondary">
              <h3 className="title mt-4 text-center">OUR STORY</h3>
              <p>
              For nearly 10 years, we worked as patient care advocates in a clinic. We saw women struggling through an unplanned pregnancy or sexually transmitted infections (STIs). Our patients left with medically accurate information on their pregnancy options or infections. We felt like something was missing to help them make the big decisions they were facing. We also wanted them to be able to take home an exercise they could work on and go back to later.
              </p>
              <p>
              At the clinic, we were using the Ottawa Personal Decision Guide adapted for pregnancy options. That decision guide asks for reasons for choosing each option. We believed it made more sense to think about the outcome or results of making that decision.
              </p>
              <p>
              At the same time, we were using a booklet by Tyndale House Publishing called <i>Making Good Decisions by Loving and Caring</i>. The booklet asked you to think about the way you make decisions (by impulse, avoidance, emotion, or analyzing) then to think about what you value. The booklet had a list of values, but nothing more. We asked our patients to narrow the values list by reading two at a time and carrying through the chosen value. We did this until they kept choosing the same value. Often, patients chose a value, and then completely went against it in their decision making. It made us wonder if they understood the value or that the result of their decision went against their value.
              </p>
              <p>
              After doing research on ethical decision making, and considering the lessons learned from these other tools, we developed a values and decision exercise - Valucision. In our exercise, we first ask people to understand what they value. We then ask them to imagine the positive and negative results of the options of a big decision, including who is affected. Finally, we give them a definition of the value and a description of the behaviors of people who prioritize that value. We also provide a list of questions to ask about the value use in their decision. Then we ask them to make a plan for carrying out their decision.
              </p>
              <p>
              We quickly discovered that the Valucision exercise could be useful for other big life decisions such as relationships (marriage, dating, and divorce), career (college and job selection), and where to retire.
              </p>
            </MDBCol>
            <MDBCol size="12" md="3" className="green-bg order-md-first">
              <MDBRow className="d-flex justify-content-center px-3 quote">
                <div>
                  <img alt="Diane Jackson" src={diane_jpg} height="144px" className="round-img p-2 img-fluid" />
                </div>
                <h6 className="quote w-100 text-center">Diane Jackson</h6>
                <p>Diane has been a mentor and coach no matter where she has worked. She is usually the one helping people with career and other life choices. Diane has volunteered at a women's clinic for more than 12 years. As a patient care advocate, she has helped more than 1,500 women explore pregnancy options and relationship stressors.</p>
                <p>Between 2018 and 2022, Diane mentored at the Advanced Technology Development Center (ATDC) at the Georgia Institute of Technology (Georgia Tech). ATDC is a startup technology business incubator helping entrepreneurs launch successful businesses. Diane also volunteered at Georgia Tech's Venture Lab where Georgia Tech faculty, research staff and students present cutting-edge research they are involved in. She provided input on potential uses for the research.</p>
                <p>For more than 34 years, Diane specialized in environmental emergency response and environmental health issues. She worked for the Agency for Toxic Substances and Disease Registry (ATSDR) and the Environmental Protection Agency (EPA). Diane has a B.S. in chemical engineering from Purdue University and is a licensed professional engineer in Georgia. Since retiring, Diane has been guest lecturing on lead and other environmental issues at several universities.</p>
              </MDBRow>
            </MDBCol>
            <MDBCol size="12" md="3" className="light-grey-bg">
              <MDBRow className="d-flex justify-content-center px-3 quote">
                <div>
                  <img alt="Cindy Kelly" src={cindy_png} height="144px" className="round-img p-2 img-fluid" />
                </div>
                <h6 className="quote title w-100 text-center">Cindy Kelly</h6>
                <p>Cindy describes herself as a freelance artist/designer, creative problem-solver and former homeschool mom with a passion for helping women discover their strengths and values. She believes that the rise of work at home scenarios will help women to thrive not only as wives and mothers but as economic contributors.</p>
                <p>Cindy volunteered at a women's clinic for more than eight years. She observed that when asking clients how they make important life-critical decisions, they often based their answer on one criterion—how they feel. This led to the creation of the Valucision model with her colleague and friend, Diane Jackson.</p>
                <p>Cindy has a BFA in Design and Printmaking from Mason Gross School of Art, Rutgers University and post-graduate studies in Design and Illustration at the Portfolio Center, Atlanta, Georgia. She worked as graphic designer until she began homeschooling her two children. Cindy is currently working as a freelance designer in Atlanta, Georgia and volunteers teaching art and art history to a local home school group.</p>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>

      <hr />
      <MDBContainer fluid>
        <h6 className="text-center">References Reviewed</h6>
        <ol>
          <li>Brown, Montague. One Minute Philosopher: Quick Answers to Help You Banish Confusion, Resolve Controversies and Explain Yourself to Others. Manchester: Sophia Institute Press, 2001. Print.</li>
          <li>CEOsage. The Ultimate Core Values List. <a href="https://scottjeffrey.com/core-values-list/">{referenceLink}</a></li>
          <li>Isaacs, David. Character Building: A Guide for Parents and Teachers. Portland: Four Courts Press, 2001. Print.</li>
          <li>Josephson Institute of Ethics. Making Ethical Decisions. <a href="https://web.engr.uky.edu/~jrchee0/CE%20401/Josephson%20EDM/Making_Ethical_Decisions.pdf">{referenceLink}</a></li>
          <li>Josephson Institute of Ethics. 12 Ethical Principles for Business Executives <a href="http://josephsononbusinessethics.com/2010/12/12-ethical-principles-for-business-executives/">{referenceLink}</a></li>
          <li>Josephson Institute of Ethics. Groundwork For  Making Effective Decisions <a href="http://josephsoninstitute.org/med-3groundwork/">{referenceLink}</a></li>
          <li>Making Good Decisions by Loving and Caring. Tyndale House Publishers, Inc. 2006</li>
          <li>Mind Tools. com. What Are Your Values? Deciding What's Most Important in Life <a href="https://www.mindtools.com/pages/article/newTED_85.htm">{referenceLink}</a></li>
          <li>Ottowa Hospital Research Institute. 2019. The Ottawa Personal Decision Guide (OPDG) <a href="https://decisionaid.ohri.ca/docs/das/OPDG.pdf">{referenceLink}</a> <a href="https://decisionaid.ohri.ca/decguide.html">{referenceLink}</a></li>
          <li>Schoolbuff.com 2020 College planning <a href="https://schoolbuff.com/wp-content/uploads/2019/05/19-College-Planning-Values-Assessment-.pdf">{referenceLink}</a> <a href="https://schoolbuff.com/worksheets/#college-planning-values-assessment">{referenceLink}</a></li>
          <li>Texas Tech University. Murdough Center for Engineering Professionalism. Recommended Core Ethical Values. 2018 <a href="http://www.depts.ttu.edu/murdoughcenter/products/resources/recommended-core-ethical-values.php">{referenceLink}</a></li>
          <li>UC San Diego. Making Ethical Decisions: Core Ethical Values. <a href="https://blink.ucsd.edu/finance/accountability/ethics/core-values.html">{referenceLink}</a></li>
          <li>Photo credit: Door_girl letizia-bordoni-IZGNcO_8CDg-unsplash, Boy in yellow shirt by Julia Cameron from Pexels</li>
        </ol>
      </MDBContainer>
    </>
  );
}

export default About;