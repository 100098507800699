import React, { FunctionComponent, useMemo } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import { value } from "../views/Values";
import DropdownCard from "./DropdownCard";
import { useAppContext } from "../AppContext";

interface Props {
  value: value;
  addToValuesBtn?: boolean;
  order?: number;
}

const ValuesCard: FunctionComponent<Props> = ({ value, addToValuesBtn = false, order }) => {
  const { appContext, dispatch } = useAppContext();
  const isInValues = useMemo(() => {
    return appContext.initialSelection.includes(value.name);
  }, [appContext, value]);

  return (
    <DropdownCard title={order ? `${order}. ${value.name}` : value.name}>
      <MDBRow>
        <MDBCol className="p-0" size="2"><img className="img-fluid" alt="icon" src={require(`../assets/img/icons/${value.name}.png`)} /></MDBCol>
        <MDBCol className="p-0" size="10"><p className="text-secondary">{value.description}</p></MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <span className="title">Ask Yourself:</span>
          {
            value.questions.map(question => {
              return <p className="text-secondary" key={question}>{question}</p>;
            })
          }
        </MDBCol>
        <MDBCol>
          <span className="title">People Who Value</span> <span className="title-secondary">{`${value.name}:`}</span>
          {
            value.people.map(point => {
              return <p className="text-secondary" key={point}>{point}</p>;
            })
          }
        </MDBCol>
      </MDBRow>
      {addToValuesBtn &&
        <MDBRow end className="mx-3">
          {!isInValues
            ? <><span className="mr-2 text-secondary">Add to your list of values</span><MDBBtn
              onClick={() => {
                dispatch({
                  type: "initialSelection",
                  payload: (prev => [...prev, value.name])
                })
              }}
              color="default"
              className="round-btn-icon p-0 m-0"
              size="sm"
            >
              <MDBIcon icon="plus" />
            </MDBBtn></>
            : <><span className="mr-2">Remove from your list of values</span><MDBBtn
              onClick={() => {
                dispatch({
                  type: "initialSelection",
                  payload: (prev => prev.filter(name => name !== value.name))
                })
              }}
              color="secondary"
              className="round-btn-icon p-0 m-0"
              size="sm"
            >
              <MDBIcon icon="minus" />
            </MDBBtn></>
          }
        </MDBRow>}
    </DropdownCard>
  );
}

export default ValuesCard;