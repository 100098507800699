import React, { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useAppContext, Stage } from '../../AppContext';
import { MDBRow, MDBInput, MDBBtn, MDBCol, MDBIcon, MDBCollapse, MDBCard, MDBCardBody } from 'mdbreact';
import prev_active from "../../assets/img/prev_active.png";
import next_active from "../../assets/img/next_active.png";
import Step1B from './Step1B';

interface Props {
  collapsed?: boolean;
  done?: boolean;
}

const Step2B: FunctionComponent<Props> = ({ collapsed = false, done = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!collapsed);

  const { appContext, dispatch } = useAppContext();
  const {
    positiveResult1You,
    positiveResult1Others,
    negativeResult1You,
    negativeResult1Others,
    positiveResult2You,
    positiveResult2Others,
    negativeResult2You,
    negativeResult2Others,
    positiveResult3You,
    positiveResult3Others,
    negativeResult3Others,
    negativeResult3You,
    option1,
    option2,
    option3,
    bigDecision,
    peopleInvolved
  } = appContext;

  const setStage = useCallback((arg: Stage) => {
    dispatch({
      type: "stage",
      payload: arg
    });
  }, [dispatch]);
  const setPositiveResult1You = useCallback((arg: string) => {
    dispatch({
      type: "positiveResult1You",
      payload: arg
    });
  }, [dispatch]);
  const setPositiveResult1Others = useCallback((arg: string) => {
    dispatch({
      type: "positiveResult1Others",
      payload: arg
    });
  }, [dispatch]);
  const setNegativeResult1You = useCallback((arg: string) => {
    dispatch({
      type: "negativeResult1You",
      payload: arg
    });
  }, [dispatch]);
  const setNegativeResult1Others = useCallback((arg: string) => {
    dispatch({
      type: "negativeResult1Others",
      payload: arg
    });
  }, [dispatch]);
  const setPositiveResult2You = useCallback((arg: string) => {
    dispatch({
      type: "positiveResult2You",
      payload: arg
    });
  }, [dispatch]);
  const setPositiveResult2Others = useCallback((arg: string) => {
    dispatch({
      type: "positiveResult2Others",
      payload: arg
    });
  }, [dispatch]);
  const setNegativeResult2You = useCallback((arg: string) => {
    dispatch({
      type: "negativeResult2You",
      payload: arg
    });
  }, [dispatch]);
  const setNegativeResult2Others = useCallback((arg: string) => {
    dispatch({
      type: "negativeResult2Others",
      payload: arg
    });
  }, [dispatch]);
  const setPositiveResult3You = useCallback((arg: string) => {
    dispatch({
      type: "positiveResult3You",
      payload: arg
    });
  }, [dispatch]);
  const setPositiveResult3Others = useCallback((arg: string) => {
    dispatch({
      type: "positiveResult3Others",
      payload: arg
    });
  }, [dispatch]);
  const setNegativeResult3You = useCallback((arg: string) => {
    dispatch({
      type: "negativeResult3You",
      payload: arg
    });
  }, [dispatch]);
  const setNegativeResult3Others = useCallback((arg: string) => {
    dispatch({
      type: "negativeResult3Others",
      payload: arg
    });
  }, [dispatch]);
  const setOption1 = useCallback((arg: string) => {
    dispatch({
      type: "option1",
      payload: arg
    });
  }, [dispatch]);
  const setOption2 = useCallback((arg: string) => {
    dispatch({
      type: "option2",
      payload: arg
    });
  }, [dispatch]);
  const setOption3 = useCallback((arg: string) => {
    dispatch({
      type: "option3",
      payload: arg
    });
  }, [dispatch]);
  const setBigDecision = useCallback((arg: string) => {
    dispatch({
      type: "bigDecision",
      payload: arg
    });
  }, [dispatch]);
  const setPeopleInvolved = useCallback((arg: string) => {
    dispatch({
      type: "peopleInvolved",
      payload: arg
    });
  }, [dispatch]);


  const toggleBtn = useMemo(() => {
    return (
      <MDBBtn
        onClick={() => setIsOpen(prev => !prev)}
        color="info"
        className="round-btn-icon p-0 m-0"
        size="sm"
      >
        {isOpen ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />}
      </MDBBtn>
    );
  }, [isOpen]);

  const nextBtn = useMemo(() => {
    return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step3)}>
      <img alt="next" height="25em" className="float-right" src={next_active} />
    </div>;
  }, [setStage]);

  const prevBtn = useMemo(() => {
    return <div className="m-5 next-btn" onClick={() => setStage(Stage.Step2A)}>
      <img alt="next" height="25em" className="float-left" src={prev_active} />
    </div>
  }, [setStage]);

  return (
    <div className="mt-5">
      <Step1B done collapsed />
      <div className="mt-5">
        {toggleBtn} <span className="title align-middle">Step 2. </span> {isOpen && <span className="title align-middle">Work On Your Big Decision</span>}
        <MDBCollapse isOpen={isOpen}>
          <MDBCard className="rounded exercise-card-bg border border-secondary mt-3">
            <MDBCardBody>
              <MDBRow middle>
                <MDBCol size="2">
                  {/* <img alt="next" height="25em" src={prev_inactive} /> */}
                </MDBCol>
                <MDBCol size="8">
                  <MDBRow middle>
                    <MDBCol size="12" md="4">
                      <strong className="text-secondary">My <span className="text-info">Big</span> Decision is...</strong>
                    </MDBCol>
                    <MDBCol size="12" md="8">
                      <MDBInput
                        type="text"
                        containerClass="m-0 p-0 d-inline"
                        className="m-0 p-0 d-inline w-100"
                        value={bigDecision}
                        getValue={value => setBigDecision(value.toString())}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow middle>
                    <MDBCol size="12" md="4">
                      <strong className="text-secondary">The people involved are...</strong>
                    </MDBCol>
                    <MDBCol size="12" md="8">
                      <MDBInput
                        type="text"
                        containerClass="m-0 p-0 d-inline"
                        className="m-0 p-0 d-inline w-100"
                        value={peopleInvolved}
                        getValue={value => setPeopleInvolved(value.toString())}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol size="2">
                  {/* <img className="float-right" alt="next" height="25em" src={next_inactive} /> */}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <p className="m-4 text-secondary">{!done && <span>Enter <strong>THREE</strong> Options for the decision in the lines below</span>}</p>
          <MDBRow>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="border border-secondary green-bg rounded">
                <MDBCardBody className="p-2 px-4">
                  <h5 className="title">Option One</h5>
                  <MDBInput
                    type='text'
                    outline background
                    containerClass="m-0"
                    hint="Type text here"
                    value={option1}
                    getValue={value => setOption1(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="border border-secondary green-bg rounded">
                <MDBCardBody className="p-2 px-4">
                  <h5 className="title">Option Two</h5>
                  <MDBInput
                    type='text'
                    outline background
                    containerClass="m-0"
                    hint="Type text here"
                    value={option2}
                    getValue={value => setOption2(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="border border-secondary green-bg rounded">
                <MDBCardBody className="p-2 px-4">
                  <h5 className="title">Option Three</h5>
                  <MDBInput
                    type='text'
                    outline background
                    containerClass="m-0"
                    hint="Type text here"
                    value={option3}
                    getValue={value => setOption3(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <p className="m-4 text-secondary">{!done && `Type in positive and negative outcomes by asking "If I choose this option, I will ________"`}</p>
          <MDBRow>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="light-grey-bg border border-secondary">
                <MDBCardBody className="p-2 px-4">
                  <h5 className="options-header text-info">Positive Result</h5>
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For you</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={positiveResult1You}
                    getValue={(value) => setPositiveResult1You(value.toString())}
                  />
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For others</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={positiveResult1Others}
                    getValue={(value) => setPositiveResult1Others(value.toString())}
                  />
                  <h5 className="options-header grey-text mt-2">Negative Result</h5>
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For you</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={negativeResult1You}
                    getValue={(value) => setNegativeResult1You(value.toString())}
                  />
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For others</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={negativeResult1Others}
                    getValue={(value) => setNegativeResult1Others(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="light-grey-bg border border-secondary">
                <MDBCardBody className="p-2 px-4">
                  <h5 className="options-header text-info">Positive Result</h5>
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For you</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={positiveResult2You}
                    getValue={(value) => setPositiveResult2You(value.toString())}
                  />
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For others</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={positiveResult2Others}
                    getValue={(value) => setPositiveResult2Others(value.toString())}
                  />
                  <h5 className="options-header grey-text mt-2">Negative Result</h5>
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For you</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={negativeResult2You}
                    getValue={(value) => setNegativeResult2You(value.toString())}
                  />
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For others</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={negativeResult2Others}
                    getValue={(value) => setNegativeResult2Others(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol size="12" md="4" className="mb-1">
              <MDBCard className="light-grey-bg border border-secondary">
                <MDBCardBody className="p-2 px-4">
                  <h5 className="options-header text-info">Positive Result</h5>
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For you</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={positiveResult3You}
                    getValue={(value) => setPositiveResult3You(value.toString())}
                  />
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For others</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={positiveResult3Others}
                    getValue={(value) => setPositiveResult3Others(value.toString())}
                  />
                  <h5 className="options-header grey-text mt-2">Negative Result</h5>
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For you</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={negativeResult3You}
                    getValue={(value) => setNegativeResult3You(value.toString())}
                  />
                  <p style={{ fontSize: "0.75em" }} className="grey-text m-0 p-0">For others</p>
                  <MDBInput
                    containerClass="my-0 py-0"
                    outline
                    type="textarea"
                    value={negativeResult3Others}
                    getValue={(value) => setNegativeResult3Others(value.toString())}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          {!done && <MDBRow end>
            <MDBCol>
              {prevBtn}
            </MDBCol>
            <MDBCol>
              {nextBtn}
            </MDBCol>
          </MDBRow>}
        </MDBCollapse>
      </div>
    </div>
  );
}

export default Step2B;

