import { value } from "../views/Values";

export const values: Array<value> = [
  {
    name: `Achievement`,
    description: `People who value achievement work to accomplish their personal and professional goals. Achievers take time to improve skills, build networks, and measure their progress toward completion of projects. Sometimes achievers may be motivated by looking good to others.  —Similar to accomplishment, competence, persistence, and respect.`,
    people: [`Work diligently to overcome obstacles  thoughtfully, carefully, and calmly`, `Know the standards that apply to work well done`, `Give priority to the things that are most important`, `Don't put things above people`, `Plan ahead for each day to make sure they are giving enough attention to what needs to be done`, `Have or seek to attain the skills necessary with sufficient motivation.`],
    questions: [`How are my goals being achieved in a way that is good for me and others?`, `How am I striving to set a good example of achievement to those involved?`, `How am I getting the skills needed to complete my goal or make this decision?`, `Am I avoiding doing the work I committed to do?`, `Am I making excuses? What are they?`],
  },
  {
    name: `Care for Others`,
    description: `People who value care for others help them and listen to them. They show compassion, forgiveness, and love toward people. They value service to others such as feeding, clothing, or providing housing. —Similar to compassion, empathy, love, and family & children.`,
    people: [`Seek activities that show compassion, generosity, and forgiveness`, `May work for communities to help the poor, or in hospitals to care for the sick`, `May neglect their own needs and may need to establish good personal boundaries.`],
    questions: [`How am I being kind in this option?`, `Am I showing compassion to all those involved (family, friends, others)?`, `How am I keeping my promises to everyone involved?`, `Am I putting my needs aside to help others?`],
  },
  {
    name: `Common Good`,
    description: `People who value the common good and community recognize the purpose of the group, country, or community and seek to support its members. They make decisions and take actions that are beneficial for most or all members. —Similar to citizenship.`,
    people: [`Cooperate with others and don't impose on others`, `Share common purpose and support members of their community`, `Seek to understand what is beneficial to all involved`, `Respect freedom, rights, beliefs, and the opinions of others, sometimes even over individual rights`, `Obey the law`, `Protect the environment`, `Ensure they are a good neighbors.`],
    questions: [`How am promoting the common good for others?`, `Am I contributing to the unifying good of the community or causing division?`, `How am I respecting the beliefs or opinions of all those involved (family, friends, others)?`, `How am I choosing what benefits most everyone involved?`, `How am I concerned for those involved?`, `How am I freely choosing to be part of my community?`],
  },
  {
    name: `Courage`,
    description: `People who value courage freely choose to risk personal well-being for the good at stake. They know that the threatening danger is serious and that the good must be protected. The person who values moral courage acts rightly in the face of popular opposition, shame, scandal, discouragement, or personal loss. —Similar to bravery and conviction. `,
    people: [`Risk their life for a good cause such as to protect family or country, to help the oppressed, or to save the innocent`, `Always act courageously as a matter of course`, `Act in accordance with their beliefs, especially in spite of criticism`, `Don't bow to peer pressure or social norms they believe are wrong`, `Speak up about what they believe is important.`],
    questions: [`Does this option involve danger or pain? Am I facing up to danger or to pain for a legitimate reason or is the cause something that cannot be justified?`, `Am I bowing to peer pressure or societal norms that are wrong in this option?`, `Is my cause something I would always do out of love and faithfulness or is it more spur of the moment?`],
  },
  {
    name: `Creativity`,
    description: `People who value creativity look for new and better ways to produce or do things. They live according to ideals of beauty, and choose according to their own tastes, talents and circumstances. —Similar to curiosity, innovation, and originality. `,
    people: [`Find better or new ways to produce something`, `Live by their ideals of imagination to elevate and inspire others`, `See  creativity as more valuable than skill`, `Are challenged by problems to look for unique solutions.`],
    questions: [`Does this option allow for my creative contribution?`, `Does this option have new, beautiful, or original results?`, `Is this option's critical feature unique?`, `How will my actions inspire others?`, `What ideal am I living up to if I choose this option?`],
  },
  {
    name: `Fairness`,
    description: `People who value fairness help others according to their need. Fairness first recognizes the dignity of the human person, but accepts that everyone is different. Fairness also recognizes excellence or talent. The person who values fairness wants to treat people justly before the law. They don't  think everyone deserves a trophy just for being present. Fairness involves justice and mercy.`,
    people: [`Are impartial in matters of justice`, `Don’t insist on their own rights over the rights of others but seek a just solution for all those involved`, `See the reality that life is not always fair in the distribution of rewards whether it is looks, talents, or material possessions`, `Recognize and respect the greater contribution or talents of others`, `Act impartially and don’t show favoritism to a particular group or race`, `Do not act out of envy, jealousy, or revenge.`],
    questions: [`How am I showing fairness by choosing this option?`, `How am I being fair to all those involved (family, friends, others)?`, `Which option helps me choose what is right over what is easy?`, `Am I taking advantage of anyone?`, `Am I acting impartially?`],
  },
  {
    name: `Faith`,
    description: `People who value faith trust that God has revealed by his authority what is true. Faith can also reflect confidence in an authority at work or in school, government or an institution. People who value their faith may use reason, prayer, service, or other spiritual practices to develop a greater understanding of their faith and strive to live by it. They are not gullible, that is, they don't trust without good reason so don't risk being taken advantage of or setting a poor example. —Similar to spirituality and devotion.`,
    people: [`Strive to live out their faith in God`, `Ask questions about what they believe and seek greater understanding`, `Trust in God and his plan over their own`, `Look for credible authorities when choosing who or what to put their faith in`, `Don’t believe everything they see, hear, or read on social media or TV`, `Attempt to conform themselves to God’s will`, `Respect the human dignity of others.`],
    questions: [`How am I living by the faith standards I say I believe in?`, `In which option will following my faith result in more good than harm?`, `Am I putting unwarranted trust in a person or institution in this option? Why?`, `Am I abusing God's grace in any of my options by using God's mercy as an excuse to act against his will?`, `Is this option influenced by allowing TV, social media, or other people telling me what to believe? Is this influence credible?`, `How am I asking God to help with this decision?`],
  },
  {
    name: `Family`,
    description: `People who value family believe that family is a top priority and provide family members love and support. Family can mean parents, children, or cousins, siblings, aunts, uncles, etc. (family of origin) or your friend family or family of choice. `,
    people: [`Communicate respectfully, avoiding harsh tones and words`, `Express love and appreciation often`, `Enjoy participating in family activities`, `Maintain family routines`, `Build family traditions around holidays, birthdays and other celebrations`, `Put family above friends and work`, `If single, practice dating that sets clear limits on time and models healthy dating to children.`],
    questions: [`How do I demonstrate that I value my current/future family/children in this option?`, `Could I damage any of the  relationships I value by any option, either now or in the future?`, `Do I value family above friends, work, or education?`],
  },
  {
    name: `Financial Stability`,
    description: `People who value financial stability consistently work hard to make sure they won’t have to worry about whether their income is enough to cover their expenses. Financial stability frees them to pursue other areas of stability-- good physical and mental health, stable friendships and communities. Financial stability provides consistency they can build on and creates a stable base from which they can grow and enrich their lives. —Similar to order.`,
    people: [`Know that money is only a tool. It doesn’t buy happiness or love`, `Don't spend more than they make`, `Take time to learn about their finances by tracking expenses and adjusting as necessary`, `Plan a budget and stick to it`, `May have an emergency fund`, `Handle credit cards wisely including paying them off  monthly`, `Are frugal but not stingy`, `Save for desired purchases`, `Invest for the future.`],
    questions: [`What makes me feel more secure: money, love, health, or faith? Why?`, `Which one of my options will make me be more secure in the future? How?`, `Do I trust in financial stability to secure my future but also believe God will provide for my needs? What does that mean for this option?`, `Which option helps me live within my means?`, `How am I investing for my future?`],
  },
  {
    name: `Health`,
    description: `People who value health take care of their mental, physical and spiritual needs. They know that staying physically active helps them manage their responsibilities. They seek spiritual stability because it helps them order life peacefully and calmly. —Similar to wholeness and  well-being.`,
    people: [`Get away regularly for spiritual practice`, `Have a good support system`, `Avoid processed food and drink lots of water`, `Cook at home more often to maintain a balanced diet and save money`, `Consume alcohol moderately and don't smoke`, `Avoid risky behaviors`, `Moderate stress with relaxation exercises or other practices`, `Plan leisure time and get plenty of sleep and exercise`, `Spend time outdoors.`],
    questions: [`How could this option affect my health/well-being? (Include physical and  mental health)`, `Could any of my options damage or result in the loss of relationships that I care about? How will that affect my health and well-being?`, `Could any of my options result in better relationships? How will that affect my health and well-being?`],
  },
  {
    name: `Honesty`,
    description: `People who value honesty are truthful and sincere. Their actions show integrity and fairness even when no one is looking. They can be relied on to do what they say they will do and don't seek to make themselves look better than they are. —Similar to trustworthiness.`,
    people: [`Avoid lies and act with integrity`, `Don't seek to make themselves look better than they are`, `Are reliable and consistent`, `Are grounded in the reality of a situation and accept what they cannot change`, `Can tell when things don't add up`, `Appreciate the trust others put in them`, `Show gratitude and humility`, `Respect boundaries and don't try to manipulate others`, `Aren't overly sensitive and don't take themselves too seriously`, `Aren't overly modest or boastful.`],
    questions: [`How am I using honesty in this option?`, `Do I understand my intentions?`, `In which option am I being honest with myself about what I value?`, `How am I being honest with all those involved (e.g., family, friends, others)?`],
  },
  {
    name: `Independence`,
    description: `People who value independence want to be free from the control, influence, and reliance on others. They stand up for themselves,   know their priorities, and prefer to handle their own problems. Sometimes independence can lead to self-centeredness. —Similar to freedom, liberty, and individuality.`,
    people: [`Are self-reliant and confident`, `Are self-motivated and know their priorities`, `Have realistic expectations`, `Don't require company for every task`, `Accept themselves and don't try to conform to others`, `Prefer to meet new people and try new things`, `May be more in control of their emotions such as anger, over-excitement, anxiety, etc.`, `Develop financial independence.`],
    questions: [`Which option makes me less reliant on others for help? Why is that important?`, `Does independence make me feel more secure than love, faith, or health? How?`, `Which option makes me the most proud? Most motivated? Most open minded?`],
  },
  {
    name: `Intelligence`,
    description: `People who value intelligence learn from experience and use reason to solve problems. They exercise good judgment and use common sense. Some may not be in touch with their emotions, but show empathy toward others. —Similar to common sense, decisiveness, and insightful.`,
    people: [`Ask questions and search for answers`, `Challenge what they hear to make sure they get a truthful answer`, `Are not arrogant`, `Enjoy their own company`, `Use experience and observation to make decisions and plans`, `Grasp the meaning behind information and can "make connections"`, `Are usually adaptable`, `Have good instincts`, `Use self-control`, `May procrastinate.`],
    questions: [`How have I gathered all the facts about my options?`, `Am I exercising self-control?`, `Where have I sought the advice of experts (if appropriate)?`, `Is there a common sense answer?`, `Am I paying attention to any insights that might be just under the surface?`],
  },
  {
    name: `Order`,
    description: `People who value order have a clear hierarchy of values for their own lives. They follow logical and efficient procedures when organizing their things, managing their time, and caring for themselves. They are often self-starters. They may not always be open to new ideas. —Similar to discipline, decisiveness, financial stability, lawfulness, and security.`,
    people: [`Prioritize their family, work, etc. in order of importance and use their time according to these priorities`, `Show clarity and consistency of thoughts and actions`, `Are prepared`, `Are generally goal-oriented`, `Know where to find things`, `Are usually punctual`, `Use manuals and diaries, lists, etc. to help them remember their responsibilities.`],
    questions: [`How does this option line up with my most important values?`, `How am I giving adequate importance to my family, work, faith, and other priorities in this option?`, `Am I responding in order of importance to this situation or reacting out of feelings like fear or confusion?`, `Am I acting out of the desire to control the decision without knowing why? How does this affect others?`],
  },
  {
    name: `Pleasure`,
    description: `People who value pleasure want to experience happiness and avoid situations that cause pain. They also enjoy human drives such as eating, exercise, sleep, and sex. —Similar to fun, enjoyment, enthusiasm, and joy.`,
    people: [`Seek what makes them feel good`, `Enjoy being the center of attention`, `Are interested in other people`, `May be creative`, `Avoid situations that cause pain or may be no fun`, `Value unique and spontaneous activities`, `May be impulsive`, `Enjoy human drives such as eating, exercise, hygiene, sleep, and sex`, `Are motivated by rewards`, `May need deadlines to finish tasks`, `May over-indulge in pleasures unless they practice moderation.`],
    questions: [`Which option brings enjoyment to those involved? Who does it reward the most?`, `Is my pleasure causing pain to myself or others involved (family, friends, others)?`, `Am I trying to avoid something painful for me? What?`, `Which option makes me happier?`],
  },
  {
    name: `Reputation`,
    description: `People who value reputation care about the standing their character has in the opinion of others. They protect the image that is closest to their deepest values (honesty, integrity, etc.) They believe a good reputation can deliver self-esteem, achievement, meaning, and help them reach their potential. They know that building a good reputation requires effort, patience, and time. They may become overly aware of their reputation and become self-centered. —Similar to integrity.`,
    people: [`Do what they say the will do`, `Encourage and respect others`, `Do consistently good work`, `Act patiently and with integrity`, `Are congenial, kind, and positive`, `Care about their character`, `Notice the needs of others and look for ways to help those in need`, `Are respectful in words and actions`, `Are honest.`],
    questions: [`How am I promoting or protecting my reputation? Will this option result in me losing my reputation?`, `How am I respecting the reputation of others involved (family, friends, others)?`, `How are I standing up for what I believe in this option?`, `How am I acting trustworthy?`, `When am I the best version of myself?`, `How am I doing what I say I will do?`],
  },
  {
    name: `Respect`,
    description: `People who value respect show deep admiration for someone or something based on their abilities, qualities, or achievements. Their own actions show integrity and sincerity so that others will respect them. They are disciplined and focus on solutions rather than problems. —Similar to achievement and reputation.`,
    people: [`Treat others the way they want to be treated`, `Take good care of their property and the property of others`, `Don't prejudge`, `Value and support human dignity`, `Don't bully, gossip, or monopolize conversations`, `Show discretion and keep confidences`, `Recognize the role of others in authority`, `Are consistent`, `Follow a moral code.`],
    questions: [`How am I showing respect of the opinions of all those involved (family, friends, others)?`, `Am I going against what I believe? How will that affect my self respect?`, `Which option would the person I respect most choose?`],
  },
  {
    name: `Responsibility`,
    description: `People who value responsibility are answerable or accountable for something within their power and expect the same from others. They are consistent and self-disciplined. —Similar to integrity and dependability.`,
    people: [`Freely choose the good of taking care of themselves and others`, `Are accountable for their actions and don’t make excuses`, `Are on time`, `Act with self-discipline and exhibit restraint when necessary`, `Admit mistakes and make amends to those they have harmed`, `Don't blame others for actions that are in their control`, `Understand that actions create certain consequences.`],
    questions: [`What responsible actions am I using in this option?`, `How am I using self-discipline?`, `Am I breaking the trust of those involved (e.g., family, friends, others)?`, `How am I taking care of others?`, `Will I be more or less reliable or responsible in the future if I choose this option?`],
  },
  {
    name: `Security`,
    description: `People who value security desire or have a sense of safety and peace of mind and less fear of loss. Security can include financial, physical, or emotional safety.`,
    people: [`Ask for help and advice from trusted sources in times of need`, `Don't let self-doubt make them overreact to difficult situations`, `Act carefully or cautiously`, `Usually assess first and then act`, `Don't compare themselves to others`, `Know their strengths and weaknesses`, `Set reasonable goals based on their strengths.`],
    questions: [`What makes me most secure -money and achievement or love and belonging?`, `Could any of my options damage or result in the loss of relationships that I care about? How will that affect my security?`, `Which one of my options will make me more secure in the future? How?`],
  },
  {
    name: `Strength`,
    description: `People who value strength use their physical, emotional, and intellectual energy to do something good, overcome an obstacle, or resist what could weaken their resolve. —Similar to assertiveness, confidence, dedication, and perseverance. `,
    people: [`Do all they can to pursue a goal once it is decided`, `Try to foresee problems with the resolve to overcome them`, `Will do hard things because they are worthwhile or good`, `Put up with physical or emotional discomfort or insecurity without complaining`, `Resist trends`, `Practice realistic optimism`, `Don't let pride hold them back.`],
    questions: [`Which option requires me to admit my own weaknesses, instead of insisting I am right?`, `Where am I using my energy for a worthy reason?`, `How could this option weaken my own initiative? Am I being influenced by others?`, `Which one of my options helps me concentrate on the positive aspect of this decision?`, `Will this option make me a stronger person? How?`, `Does this option provide a platform for further growth?`],
  },
];

