import React, { FunctionComponent } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBAnimation } from 'mdbreact';
import { faqs } from '../content/faqs';
import DropdownCard from '../components/DropdownCard';

interface Props {}

export interface faq {
  question: string;
  answer: JSX.Element;
}

const FAQs: FunctionComponent<Props> = (props) => {
  const cards = faqs.map((faq, index) => {
    return (
      <MDBCol key={faq.question + index} size="12" className="mb-2">
        <MDBAnimation type="zoomInUp" delay={`${index * 100}ms`}>
          <DropdownCard title={faq.question}>
            {faq.answer}
          </DropdownCard>
        </MDBAnimation>
      </MDBCol>
    );
  });

  return (
    <MDBContainer className="mt-5 pt-5">
      <MDBRow className="mt-5">
        <MDBCol size="12" lg="6">
          <MDBRow>
            {cards.slice(0, 6)}
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" lg="6">
          <MDBRow>
            {cards.slice(6)}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}


export default FAQs;